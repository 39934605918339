import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { CheckInPage } from './check-in/check-in.page';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from './services/storage.service';
import { FormInlineComponent } from './form-inline/form-inline.component';
import { FlagSettingsComponent } from './flag-settings/flag-settings.component';
import { BadgesComponent } from './badges/badges.component';
import { ManageChurchesComponent } from './manage/manage-churches/manage-churches.component';
import { ManageRegistrationsComponent } from './manage/manage-registrations/manage-registrations.component';
import { Drupal7ServicesModule } from './services/drupal7/drupal7-services.module';
import { DrupalConstants } from './services/drupal7/application/drupal-constants';
import { PaymentPage } from './payment/payment.page';
import { BadgesNewComponent } from './badges-new/badges.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    CheckInPage,
    PaymentPage,
    ManageChurchesComponent,
    ManageRegistrationsComponent,
    BadgesComponent,
    BadgesNewComponent,
    FormInlineComponent,
    FlagSettingsComponent,
  ],
  imports: [
    BrowserModule,
    Drupal7ServicesModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgxQRCodeModule,
    IonicStorageModule.forRoot({
      name: '__slc_asia_registration',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule
],
  providers: [
    LocalNotifications,
    NativeStorage,
    Storage,
    StorageService,
    HTTP,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private storageService: StorageService) {
    DrupalConstants.Settings = {
      apiHost: 'slconferenceasia.com',
      apiEndPoint: 'api',
      language: 'und',
      requestTimeout: 15000,
      apiProtocol: 'https',
      allowOffline: true,
      cookieHeader: true,
      sessionDays: 23
    };
    this.storageService.get('session').then(connection => {
      DrupalConstants.Connection = connection;
    });
  }
}
