/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntityServiceCustom } from './entity.service';
import { MessagesService } from './messages.service';
import { addHours, format, getUnixTime, parseISO } from 'date-fns';
import { KeyValue } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from './storage.service';
import { DrupalEntityBundleInfo, DrupalFormControlObject } from './drupal7/models/form-controls';
import { FieldGroup } from './drupal7/models/forms';
import { ViewService } from './drupal7/drupal7-services.module';
import { ViewOptions } from './drupal7/models/view';
import { SchemaService } from './drupal7/schema/schema.service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(
    private fb: FormBuilder,
    public message: MessagesService,
    private alertCtrl: AlertController,
    private entity: EntityServiceCustom,
    private viewService: ViewService,
    private schemaService: SchemaService,
    public storage: StorageService
  ) {}

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value.localeCompare(b.value);


  async getForm(session: any, entityType: string, entityBundle: string, entitySubmitLabel: string = 'Submit', previewLabel: string = 'Preview', externalSource: boolean = false, viewMode: string = 'form', extra: string = 'none') {
    const requestOptions = {headers: new HttpHeaders({'Content-Type': 'application/json',})};
    const fields = [];
    const formFields = [];
    const nestedFieldGroups = [];
    let bundleInfo: DrupalEntityBundleInfo;
    await import('../../assets/'+ entityType + '/' + entityBundle + '.json').then(async (controls) => {
      bundleInfo = controls.default.bundle_info;
      for (const group of controls.default.form_fields as DrupalFormControlObject[]) {
        if (group.ionic_type === 'field_group' && group.access) {
          const newFieldGroup: FieldGroup = {
            field_name: group.field_name,
            label: group.label,
            description: group.description,
            type: group.type,
            ionic_type: group.ionic_type,
            status: group.status,
            access: group.access,
            visible: group.visible,
            fields: []
          };
          for (const field of group.fields) {
            const fieldAccess = this.checkFieldPermission(field, session);
            if (fieldAccess) {
              if (field.fields) {
                const nestedFields = [];
                for (const nestedField of field.fields) {
                  const nestedFieldAccess = this.checkFieldPermission(nestedField, session);
                  if (nestedFieldAccess) {
                    nestedFields.push(nestedField);
                  }
                }
                field.fields = nestedFields;
                fields.push(field);
                nestedFieldGroups.push(field);
                newFieldGroup.fields.push(field);
              } else {
                if (field.type === 'entityreference' && !field.options?.length) {
                  await this.getEntityRefOptions(field, null);
                }
                fields.push(field);
                newFieldGroup.fields.push(field);
              }
            }
          }
          formFields.push(newFieldGroup);
        } else {
          const fieldAccess = this.checkFieldPermission(group, session);
          if (group.type === 'entityreference' && !group.options?.length) {
            await this.getEntityRefOptions(group, null);
          }
          if (fieldAccess) {
            fields.push(group);
          }
          if (group.access && fieldAccess) {
            formFields.push(group);
          }
        }
      }
    });
    const formGroup = await this.buildForm(fields);
    const res = {
      form_fields: formFields,
      formGroup,
      nestedFieldGroups,
      entityType,
      entityBundle,
      bundle_info: bundleInfo,
      formSubmitLabel: entitySubmitLabel,
      formPreviewLabel: previewLabel,
      activeIndex: 0
    };
    return res;
  }

  async getExternalForm(session: any, entityType: string, entityBundle: string) {

    /*
       Send to backend API. The custom module services_ionic for Drupal 7 was
       created to send a JSON response of any entity structure and have it
       be immediately immplemented to the app without any changes.
       The JSON response contains bundle_info: DrupalEntityBundleInfo and form_fields: DrupalFormControlObject
    */
      const fields = [];
      const fieldGroups = [];
      const nestedFieldGroups = [];
      const bundleInfo = [];
      await this.schemaService.getSchema('entityform_type', entityBundle, 'form').then(async (controls) => {
        bundleInfo.push(controls.bundle_info);
        for (const group of controls.form_fields as DrupalFormControlObject[]) {
          if (group.ionic_type === 'field_group' && group.access) {
            const newFieldGroup: FieldGroup = {
              field_name: group.field_name,
              label: group.label,
              description: group.description,
              type: group.type,
              ionic_type: group.ionic_type,
              status: group.status,
              access: group.access,
              visible: group.visible,
              fields: []
            };
            for (const field of group.fields) {
              const fieldAccess = this.checkFieldPermission(field, session);
              if (fieldAccess) {
                if (field.fields) {
                  const nestedFields = [];
                  for (const nestedField of field.fields) {
                    const nestedFieldAccess = this.checkFieldPermission(nestedField, session);
                    if (nestedFieldAccess) {
                      nestedFields.push(nestedField);
                    }
                  }
                  field.fields = nestedFields;
                  fields.push(field);
                  nestedFieldGroups.push(field);
                  newFieldGroup.fields.push(field);
                } else {
                  if (field.type === 'entityreference' && !field.options?.length) {
                    await this.getEntityRefOptions(field, null);
                  }
                  fields.push(field);
                  newFieldGroup.fields.push(field);
                }
              }
            }
            fieldGroups.push(newFieldGroup);
          } else {
            const fieldAccess = this.checkFieldPermission(group, session);
            if (group.type === 'entityreference' && !group.options?.length) {
              await this.getEntityRefOptions(group, null);
            }
            if (fieldAccess) {
              fields.push(group);
            }
            if (group.access && fieldAccess) {
              fieldGroups.push(group);
            }
          }
        }
      });
      const formGroup = await this.buildForm(fields);
      const sortedFields = fieldGroups.sort((a, b) => this.originalOrder(a, b));

      const res = {
        formFields: sortedFields,
        formGroup,
        nestedFieldGroups,
        entityType,
        entityBundle,
        bundleInfo: bundleInfo[0],
      };
      return res;
  }


  checkFieldPermission(field: DrupalFormControlObject, session: any): boolean {
    if (field.status) {
      if (field.field_permissions_role_access) {
        let res: boolean;
        Object.keys(session.user.roles).map(key => {
          if (field.field_permissions_role_access[key]) {
            const index = field.field_permissions_role_access[key].perms.indexOf('create ' + field.field_name);
            if (index > -1) {
              return res = true;
            } else {
              return res = false;
            }
          } else {
            return res = false;
          }
        });
        return res;
      } else if (field.create) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async updateField(entity: any, key: string, session: any, formSchema: any, requestOptions: any) {
    const form = await this.getForm(session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      for (const g of form.form_fields) {
        if (g.fields) {
          const formField: DrupalFormControlObject = g.fields.filter(o => o.field_name === key)[0];
          const inputs = [];
          let type = 'text';

          switch (formField.ionic_type) {
            case 'list':
            case 'radio':
              type = 'radio';
              if (formField.options) {
                for (const option of formField.options) {
                  inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
                }
              }
              break;
            case 'boolean':
              type = 'radio';
              inputs.push({name: key, id: key, label: 'Yes', value: 1, checked: entity[key] === 1 ? true : false, type});
              inputs.push({name: key, id: key, label: 'No', value: 0, checked: entity[key] === 0 ? true : false, type});
              break;
            case 'input':
              type = 'text';
              inputs.push(
                {
                  name: key,
                  type,
                  id: key,
                  placeholder: '',
                  value: entity[key]
                }
              );
              break;
            case 'date':
              inputs.push(
                {
                  name: key,
                  type: formField.ionic_type,
                  id: key,
                  placeholder: '',
                  value: entity[key],
                  presentation: formField.presentation
                }
              );
              break;
            case 'telephone':
              type = 'tel';
              inputs.push(
                {
                  name: key,
                  type,
                  id: key,
                  placeholder: '',
                  value: entity[key]
                }
              );
              break;
            default:
              inputs.push(
                {
                  name: key,
                  type: formField.type,
                  id: key,
                  placeholder: '',
                  value: entity[key]
                }
              );
              break;
          }

          if (this.isEntityRef(entity, key, formSchema)) {
            const refInputs = [];
            const url = `available/${key}`;
            const options: ViewOptions = {
              display_id: `available_${key}`,
              convert_options: true
            };
            const entityRefOptions = await this.viewService.getView(url, options);
            for (const option of entityRefOptions) {
              refInputs.push({label: option.title, value: option.nid, checked: entity[key] === option.nid ? true : false, type: 'radio'});
            }
            const fixedArr = refInputs.sort((a, b) => this.reverseKeyOrder(a, b));

            this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, fixedArr).then(val => {
              if (val) {
                return entity[key] = val;
              }
            });
          } else {
            this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
              if (val) {
                return entity[key] = val;
              }
            });
          }

        }
      }
    }
  }

  isEntityRef(entity: any, key: string, formSchema: any) {
    if (formSchema.formGroup.controls[key]) {
      for (const g of formSchema.form) {
        if (g.fields) {
          const formField: DrupalFormControlObject = g.fields.filter(o => o.field_name === key)[0];
          if (formField.type === 'entityreference' && formField.field_name === key) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }

  async buildForm(controls: Array<DrupalFormControlObject>) {
    const entityForm = this.fb.group({});
        for (const control of controls) {
          if (control.fields && !control.multiple) {
            const newGroup = new FormGroup({});
            if (control.required) {
              newGroup.setValidators(Validators.required);
            }
              for (const child of control.fields) {
              if (child.create) {
                const newControl = new FormControl();
                if (child.required) {
                  newControl.setValidators(Validators.required);
                }
                if (child.type === 'email') {
                  newControl.setValidators(Validators.email);
                }
                if (child.default_value) {
                  newControl.setValue(child.default_value);
                }
                newGroup.addControl(child.field_name, newControl);
              }
            }
            entityForm.addControl(control.field_name, newGroup);
        } else if (control.multiple && control.ionic_type === 'group' || control.multiple && control.ionic_type === 'table_group') {
          const newArray = new FormArray([]);
          const newGroup = new FormGroup({});
          if (control.required) {
            newGroup.setValidators(Validators.required);
          }
          for (const child of control.fields) {
            if (child.create) {
              const newControl = new FormControl();
              if (child.required) {
                newControl.setValidators(Validators.required);
              }
              if (child.type === 'email') {
                newControl.setValidators(Validators.email);
              }
              if (child.default_value) {
                newControl.setValue(child.default_value);
              }
              newGroup.addControl(child.field_name, newControl);
            }
          }
          newArray.push(newGroup);
          entityForm.addControl(control.field_name, newArray);
        } else if (control.multiple && control.ionic_type === 'array') {
          const newArray = new FormArray([]);
          newArray.push(new FormControl(''));
          entityForm.addControl(control.field_name, newArray);
        } else {
          const newFormControl = new FormControl();
          if (control.required) {
            newFormControl.setValidators(Validators.required);
          }
          if (control.type === 'email') {
            newFormControl.setValidators(Validators.email);
          }
          if (control.default_value) {
            newFormControl.setValue(control.default_value);
          }
          if (control.type === 'datetime') {
            newFormControl.patchValue(new Date().toISOString());
          }
            entityForm.addControl(control.field_name, newFormControl);
          }
        }
        return entityForm;
  }

  async getEntityRefOptions(fieldElement: DrupalFormControlObject, entity: any) {
    const url = `available/${fieldElement.field_name}`;
    const options: ViewOptions = {
      display_id: `available_${fieldElement.field_name}`,
      convert_options: true
    };
    if (!entity) {
      fieldElement.options = await this.viewService.getView(url);
    } else {
      const contextualFilter = entity[fieldElement.field_name] ? entity[fieldElement.field_name] : null;
      if (contextualFilter) {
        options.args = [contextualFilter];
      }
      fieldElement.options = await this.viewService.getView(url, options);
    }
    return fieldElement;
  }

  async getAutoCompleteOptions(e: any, fieldElement: DrupalFormControlObject) {
    console.log(e);
    const url = `available/${fieldElement.field_name}`;
    const options: ViewOptions = {
      display_id: `available_${fieldElement.field_name}`,
      convert_options: true,
      filters: {
        id: e.detail.value
      }
    };
    return fieldElement.options = await this.viewService.getView(url, options);
  }

  loadEntity(entity: any, form: FormGroup, fields: DrupalFormControlObject[], nestedFields: DrupalFormControlObject[]) {
    Object.keys(form.controls).map(key => {
      const control = form.get(key);
      const formField = this.getFieldControl(key, form, fields);
      if (control && !control['controls'] && formField) {
        if (typeof entity[key] === 'object' && entity[key] || !Array.isArray(entity[key]) && entity[key] !== null) {
          if (formField.ionic_type === 'entityreference' || formField.ionic_type === 'autocomplete') {
            this.getEntityRefOptions(formField, entity).then(() => {
                const entityRefValue = entity[key]?.und ? entity[key].und[0].target_id.toString() : entity[key];
                control.setValue(entityRefValue);
            });
          } else if (entity.entity_type === 'user' && formField.type === 'email' && !entity[key]) {
            // Set the value of the email address to be the user's email
            control.setValue(entity.mail);
          } else if (environment.numberToStringFields.indexOf(formField.field_name) > -1) {
            const numValue = entity[key];
            control.setValue(numValue.toString());
          } else {
            control.setValue(entity[key]);
          }
          if (formField.ionic_type === 'date' || formField.ionic_type === 'enddate_get') {
            if (entity[key] !== undefined) {
              const formattedDate = format(parseISO(entity[key]), 'yyyy-MM-dd');
              control.setValue(formattedDate);
            }
          }
        } else if (entity[key]) {
          if (formField.ionic_type === 'entityreference' || formField.ionic_type === 'autocomplete') {
            this.getEntityRefOptions(formField, entity).then(() => {
                if (entity[key]) {
                  const entityRefValue = entity[key]?.und ? entity[key].und[0].target_id.toString() : entity[key];
                  control.setValue(entityRefValue);
                }
            });
          } else if (entity.entity_type === 'user' && formField.type === 'email' && !entity[key]) {
            // Set the value of the email address to be the user's email
            control.setValue(entity.mail);
          } else if (formField.ionic_type === 'date' || formField.ionic_type === 'enddate_get') {
            if (entity[key]) {
              const formattedDate = format(parseISO(entity[key]), 'yyyy-MM-dd');
              control.setValue(formattedDate);
            }
          } else if (Array.isArray(entity[key]) && entity[key].length) {
            control.setValue(entity[key]);
          } else if (!Array.isArray(entity[key])) {
            control.setValue(entity[key]);
          } else {
            if (entity[key]) {
              control.setValue(entity[key]);
            }
          }
        }
      }
      if (Array.isArray(control['controls']) && control['controls']) {
        let z = 0;
        const fieldCollection = nestedFields.filter(o => o.field_name === key)[0];
        // Remove the initial control since we will add controls for each entity loaded
        this.removeArrayGroup(key, z, form);
        for (const arrayItem of entity[key]) {
          this.addArrayGroup(key, fieldCollection.fields, form);
          Object.keys(arrayItem).map(nestedKey => {
            const arrControl = control['controls'][z].get(nestedKey);
            if (arrControl) {
              arrControl.setValue(arrayItem[nestedKey]);
            }
          });
          z++;
        }
      } else if (typeof control['controls'] === 'object' && control['controls'] !== null) {
        Object.keys(control['controls']).map(nestedKey => {
          const nestedControl = control.get(nestedKey);
          nestedControl.setValue(entity[key + '_' + nestedKey]);
        });
      }

    });

  }

  convertObjectToArray(object) {
    const newArray = [];
    Object.keys(object).map(key => {
      key = object[key];
      if (typeof(object[key]) === 'object' && !Array.isArray(object[key]) && object[key] !== null) {
        Object.entries(object[key]).map(([nestedKey, nestedValue], x) => {
          if (key === nestedKey) {
            object[key] = nestedValue;
          }
        });
      } else {
        newArray.push(key);
      }
      return newArray;
    });
    return newArray;
  }

  goToFieldGroup(i, form: FormGroup, fields: DrupalFormControlObject[]) {
    let x = 0;
    for (const fieldGroup of fields) {
      if (x === i - 1) {
        for (const field of fieldGroup.fields) {
          if (!form.controls[field.field_name].valid &&
            form.controls[field.field_name].hasValidator(Validators.required)) {
            this.fixErrors(field);
            return;
          }
        }
        fields[x].access = false;
      } else if (x === i ) {
        fields[x].access = true;
        return x;
      } else {
        fields[x].access = false;
      }
      x++;
    }
  }

  addArrayField(arrayName, form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    control.push(new FormControl(''));
  }

  addArrayGroup(arrayName, fields, form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    const oneGroup = new FormGroup({});
    fields.map(child => {
      oneGroup.addControl(child.field_name, new FormControl());
    });
    control.push(oneGroup);
  }

  removeArrayGroup(arrayName, index, form: FormGroup) {
    const control = this.getFormArray(arrayName, form);
    control.removeAt(index);
  }


  checkNextGroup(i, fields: DrupalFormControlObject[]) {
    fields[i + 1].visible = true;
  }

  getFormArray(key, form: FormGroup) {
    return form.controls[key] as FormArray;
  }

  updateAdministrativeAreas(country, options, addressField, currentAdministrativeArea: any) {
    options = currentAdministrativeArea.filter(administrativeArea => administrativeArea.key === country.detail.value);
    if (options[0].value) {
      let newOptions = {};
      newOptions = Object.keys(options[0].value)
      .map(key => {
        const value = options[0].value[key];
        newOptions = {key, value};
        return newOptions;
      });
      currentAdministrativeArea = newOptions;
    } else {
      currentAdministrativeArea = null;
    }
    return currentAdministrativeArea;
  }

  async fixErrors(field) {
    const alert = await this.alertCtrl.create({
      header: 'Missing Information',
      message: '<strong>' + field.label + '</strong> is required.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          cssClass: 'primary',
        }
      ]
    });
    await alert.present();
  }

  getFieldControl(key: string, form: FormGroup, fields: DrupalFormControlObject[]) {
    if (form.controls[key]) {
      for (const field of fields) {
        if (field.fields) {
          const formField: DrupalFormControlObject = field.fields.filter(o => o.field_name === key)[0];
          if (formField?.field_name === key) {
            return formField;
          }
        } else {
          return fields.filter(o => o.field_name === key)[0];
        }
      }
    }
  }


  convertObjectWithNestedKey(object) {
    Object.keys(object).map(key => {
      const val = object[key];
      if (typeof(val) === 'object' && !Array.isArray(val) && val !== null) {
        Object.entries(val).map(([nestedKey, nestedValue], x) => {
          if (key === nestedKey) {
            return object[key] = nestedValue;
          }
        });
      }
    });
    return object;
  }

  formatDate(form, field) {
    if (form.value[field.field_name] !== null) {
      switch(field.presentation) {
        case 'date': {
          return format(parseISO(form.value[field.field_name]), 'EEEE, MMMM dd, yyyy');
        }
        case 'month-year': {
          return format(parseISO(form.value[field.field_name]), 'MMMM yyyy');
        }
        case 'month': {
          return format(parseISO(form.value[field.field_name]), 'MMMM');
        }
        case 'year': {
          return format(parseISO(form.value[field.field_name]), 'yyyy');
        }
        default: {
          return format(parseISO(form.value[field.field_name]), 'yyyy-MM-dd');
        }
      }
    } else {
      return;
    }
  }

  formatNodeFormFieldValuesFormControl(field: DrupalFormControlObject, form: FormGroup) {
    if (!field.required && form.value[field.field_name] === null) {
      delete form.value[field.field_name];
    }
    switch(field.ionic_type) {
      case 'markup':
        delete form.value[field.field_name];
        break;
      case 'date':
        const unixTime = new Date(form.value[field.field_name]).valueOf().toString().substring(0, 10);
        // const formattedString = format(parseISO(this.entityForm.value[field.field_name]), 'yyyy-MM-dd HH:mm:ss');
        form.value[field.field_name] = unixTime;
        break;
      case 'enddate_get':
        Object.keys(form.value[field.field_name])
        .map(key => {
          const value = form.value[field.field_name][key];
          form.value[field.field_name][key] = format(parseISO(value), 'yyyy-MM-dd HH:mm:ss');
        });
        break;
      default:
        break;
    }
    if (field.type === 'commerce_price') {
      form.value[field.field_name] = {amount: form.value[field.field_name] * 100, currency_code: 'USD'};
    }
    if (field.text_processing) {
      form.value[field.field_name] = {value: form.value[field.field_name]};
    }
    return form;
  }

  prepareFormValuesFormControl(fields: Array<DrupalFormControlObject>, form: FormGroup) {
    for (const field of fields) {
      if (field.ionic_type === 'field_group') {
        for (const subfield of field.fields) {
          this.formatNodeFormFieldValuesFormControl(subfield, form);
        }
      } else {
        this.formatNodeFormFieldValuesFormControl(field, form);
      }
    }
    return form.value;
  }

  prepareFormValues(fields: Array<DrupalFormControlObject>, form: FormGroup) {
    const result = {};
    for (const field of fields) {
      if (field.ionic_type === 'field_group') {
        for (const subfield of field.fields) {
          this.formatFormFieldValuesForDrupal(subfield, form, result);
        }
      } else {
        this.formatFormFieldValuesForDrupal(field, form, result);
      }
    }
    return result;
  }

  formatFormFieldValuesForDrupal(field: DrupalFormControlObject, form: FormGroup, result: any) {
    switch(field.ionic_type) {
      case 'markup':
        break;
      case 'date':
        // Convert the time to unix and add 8 hours for the timezone offset
        const unixTime = getUnixTime(addHours(new Date(form.value[field.field_name]), 8));
        result[field.field_name] = unixTime;
        break;
      case 'enddate_get':
        Object.keys(form.value[field.field_name])
        .map(key => {
          // Convert the time to unix and add 8 hours for the timezone offset
          const nestedUnixTime = getUnixTime(addHours(new Date(form.value[field.field_name][key]), 8));
          result[field.field_name][key] = nestedUnixTime;
        });
        break;
      default:
        result[field.field_name] = form.value[field.field_name];
        break;
    }
    if (field.type === 'commerce_price') {
      result[field.field_name] = {amount: form.value[field.field_name] * 100, currency_code: 'USD'};
    }
    if (field.text_processing) {
      result[field.field_name] = {value: form.value[field.field_name]};
    }

    if (!field.required && form.value[field.field_name] === null) {
      delete result[field.field_name];
    }
    return result;
  }

  conditionalField(event, dependeeField, form: FormGroup, fields: DrupalFormControlObject[]) {
    if (dependeeField?.conditional_controlling) {
      let v;
      if (event.detail.checked !== undefined) {
        v = event.detail.checked;
      } else {
        v = event.detail.value;
      }
      for (const condition of dependeeField.conditional_controlling) {
        switch(condition.field_state) {
          case 'visible': {
            if (condition.key.includes(v)) {
              this.switchConditionalFields(condition, true, dependeeField, form, fields);
            } else {
              this.switchConditionalFields(condition, false, dependeeField, form, fields);
            }
            break;
          }
          case 'hidden': {
            if (condition.key.includes(v)) {
              this.switchConditionalFields(condition, false, dependeeField, form, fields);
            } else {
              this.switchConditionalFields(condition, true, dependeeField, form, fields);
            }
            break;
          }
          case 'required': {
            for (const dependentField of condition.field_names) {
              const fieldControl = form.get(dependentField);
              for (const fieldGroup of fields) {
                const index = fieldGroup.fields.map((o) => o.field_name).indexOf(dependentField);;
                this.conditionalControlledByField(fieldGroup.fields[index], fieldControl, form);
              }
            }
          }
        }
      }
    }
  }


  conditionalControlledByField(dependentField, control, form: FormGroup) {
    if (dependentField.conditional_controlled_by) {
        if (!form.value.register_wcbc_alumni) {
          dependentField.access = false;
          if (dependentField.required !== undefined) {
            dependentField.required = false;
          }
          control.removeValidators(Validators.required);
        } else {
          dependentField.access = true;
          if (!control.hasValidator(Validators.required)) {
            if (dependentField.required !== undefined) {
              dependentField.required = true;
              control.addValidators(Validators.required);
            }
          }
        }
    }
  }

  switchConditionalFields(condition, newState, dependeeField, form: FormGroup, fields: DrupalFormControlObject[]) {
    for (const dependentField of condition.field_names) {
      for (const fieldGroup of fields) {
        const index = fieldGroup.fields.map((o) => o.field_name).indexOf(dependentField);;
        if (index !== -1 && index !== undefined) {
          fieldGroup.fields[index].access = newState;
          const fieldControl = form.get(dependentField);
          if (!newState) {
            if (fieldControl.hasValidator(Validators.required)) {
              if (fieldGroup.fields[index].required !== undefined) {
                fieldGroup.fields[index].required = false;
                if (fieldGroup.fields[index].fields) {
                  for (const field of fieldGroup.fields[index].fields) {
                    if (field.required !== undefined) {
                      field.required = false;
                      if (fieldControl['controls']) {
                        fieldControl['controls'][field.field_name].removeValidators(Validators.required);
                        if (fieldControl['controls'][field.field_name].errors) {
                          fieldControl['controls'][field.field_name].errors.required = undefined;
                        }
                      }
                    }
                  }
                }
              }
              fieldControl.removeValidators(Validators.required);
            }
          } else if (fieldGroup.fields[index].required !== undefined) {
            fieldGroup.fields[index].required = true;
            if (fieldGroup.fields[index].fields) {
              for (const field of fieldGroup.fields[index].fields) {
                if (field.required !== undefined) {
                  field.required = true;
                  if (fieldControl['controls']) {
                    fieldControl['controls'][field.field_name].addValidators(Validators.required);
                    if (fieldControl['controls'][field.field_name].errors) {
                      fieldControl['controls'][field.field_name].errors.required = true;
                    }
                  }
                }
              }
            }
            fieldControl.addValidators(Validators.required);
          }
          if (fieldGroup.fields[index].conditional_controlled_by) {
            this.conditionalControlledByField(fieldGroup.fields[index], fieldControl, form);
          }
        }
      }
    }

  }

}
