/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { UserServiceCustom } from '../services/user.service';
import { FormsService } from '../services/forms.service';
// Import JSON Files
import AdministrativeArea from '../../assets/address/administrative_area.json';
import USStates from '../../assets/address/us_states.json';
import { MessagesService } from '../services/messages.service';
import { environment } from '../../environments/environment.prod';
import { Navigation, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { UserAccount } from '../services/drupal7/models/user';
import { DrupalEntityBundleInfo, DrupalFormControlObject } from '../services/drupal7/models/form-controls';
import { SystemConnection, ViewOptions } from '../services/drupal7/models';
import { MainService, EntityService, UserService,
  CommerceCustomerProfileService, ViewService } from '../services/drupal7/drupal7-services.module';
import { EntityServiceCustom } from '../services/entity.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-form-inline',
  templateUrl: 'form-inline.component.html',
  styleUrls: ['form-inline.component.scss']
})
export class FormInlineComponent implements OnInit {

  @Input() entityType: string;
  @Input() entityBundle: string;
  @Input() author: string;
  @Input() loadedEntity: any;
  @Input() modalMode = false;

  pageTitle: string;
  form: FormGroup;
  bundleInfo: DrupalEntityBundleInfo;
  formFields: Array<DrupalFormControlObject>;
  nestedFieldGroups: Array<DrupalFormControlObject>;
  activeIndex: number;
  currentURL = this.router.url.split('/');

  formSubmitLabel: string;
  formPreviewLabel: string;
  loading: HTMLIonLoadingElement;

  administrativeArea = AdministrativeArea;
  dynamicAdministrativeArea: any;
  states: object;
  minDate = '1900';
  maxDate = (new Date().getFullYear() + 3).toString();

  constructor(
    private fb: FormBuilder,
    private mainService: MainService,
    private commerceCustomerProfileService: CommerceCustomerProfileService,
    private modalController: ModalController,
    public message: MessagesService,
    private user: UserServiceCustom,
    private loadingCtrl: LoadingController,
    private userService: UserService,
    private entityService: EntityService,
    private router: Router,
    private entity: EntityServiceCustom,
    private forms: FormsService,
    private viewService: ViewService,
    public storage: StorageService
  ) {
    let label = 'Save';
    if (!this.modalMode) {
      const nav: Navigation = this.router.getCurrentNavigation();
      this.entityType = this.currentURL[1];
      this.entityBundle = this.currentURL[2];
      this.author = this.currentURL[3];
      if (this.currentURL[3] === 'create') {
        this.pageTitle = this.currentURL[3] + ' ' + this.currentURL[2].replace('_', ' ');
      } else if (this.currentURL[4]) {
        this.pageTitle = this.currentURL[4] + ' ' + this.currentURL[2].replace('_', ' ');
      } else {
        this.pageTitle = this.currentURL[1] + ' ' + this.currentURL[2].replace('_', ' ');
      }
      if (nav?.extras && nav.extras.state) {
        this.entityType = nav.extras.state.entityType;
        this.entityBundle = nav.extras.state.entityBundle;
        this.pageTitle = nav.extras.state.pageTitle;
        if (nav.extras.state.loadedEntity) {
          this.loadedEntity = nav.extras.state.loadedEntity;
        }
      }
      if (this.entityType === 'user' && this.entityBundle === 'register') {
        this.pageTitle = 'Create User Account';
        label = 'Create and continue';
      }
    }
    this.initialize(this.entityBundle, label);
    this.dynamicAdministrativeArea = USStates;
  }

  get errorControl() {
    return this.form.controls;
  }

  ngOnInit() {
  }

  async initialize(bundle?: string, label: string = 'Save') {
    console.log(bundle);
    if (bundle) {
      this.entityBundle = bundle;
      this.getForm(this.entityType, bundle, label);
    } else {
      this.getForm(this.entityType, this.entityBundle, label);
    }
  }

  navigateToPage(url: string) {
    this.router.navigate([url], {state: {
        userAccount: this.loadedEntity
    }});
  }

   async getAutoCompleteOptions(e: any, fieldElement: DrupalFormControlObject) {
    return this.forms.getAutoCompleteOptions(e, fieldElement);
  }

  async getForm(entityType, entityBundle,
    entitySubmitLabel = 'Submit', previewLabel = 'Preview', externalSource = false, viewMode = 'form', extra = 'none') {
      this.loading = await this.loadingCtrl.create({});
      await this.loading.present();
    const session: SystemConnection = await this.storage.get('session');
    this.forms.getForm(session, entityType,
      entityBundle, entitySubmitLabel, previewLabel, externalSource, viewMode, extra).then(async (res) => {
      this.activeIndex = res.activeIndex;
      this.formFields = res.form_fields;
      this.form = res.formGroup;
      this.nestedFieldGroups = res.nestedFieldGroups;
      this.bundleInfo = res.bundle_info;
      this.formPreviewLabel = res.formPreviewLabel;
      this.formSubmitLabel = res.formSubmitLabel;
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
      }

      if (this.loadedEntity) {
        this.loadEntity();
      } else if (!this.loadedEntity && this.currentURL[4] && this.currentURL[4] === 'edit') {
        this.get();
      }
    });
  }

  async get() {
    const options: ViewOptions = {
      display_id: 'app_order_delegates',
      args: [this.currentURL[3]],
    };
    return this.viewService.getView('registration', options).then(async (res: any) => {
      const result = [];
      const items = [];
      if (res[0]) {
        Object.keys(res).map(key => key !== 'pager' ? items.push(res[key]) : null);
        for (const item of items) {
          result.push(item);
        }
        const uniqueResult = result;
        this.mainService.removeProps(uniqueResult,['pager']);
        this.entity.setupQRCode(uniqueResult);
        this.entity.setupUniqueOrders(uniqueResult);
        this.entity.setupChildcare(uniqueResult);
        console.log(uniqueResult);
        this.loadedEntity = uniqueResult[0];
        return this.loadEntity();
      }
    });
  }

  async loadEntity() {
    const entity = this.loadedEntity.user ? this.loadedEntity.user : this.loadedEntity;
    this.forms.loadEntity(entity, this.form, this.formFields, this.nestedFieldGroups);
  }

  goToFieldGroup(i) {
    this.forms.goToFieldGroup(i, this.form, this.formFields);
  }

  addArrayField(arrayName) {
    this.forms.addArrayField(arrayName, this.form);
  }

  addArrayGroup(arrayName, fields) {
    this.forms.addArrayGroup(arrayName, fields, this.form);
  }

  removeArrayGroup(arrayName, index) {
    this.forms.removeArrayGroup(arrayName, index, this.form);
  }

  getFormArray(key) {
    return this.forms.getFormArray(key, this.form);
  }

  conditionalField(event, dependeeField) {
    this.forms.conditionalField(event, dependeeField, this.form, this.formFields);
  }

  updateAdministrativeAreas(country, options, addressField) {
    this.dynamicAdministrativeArea = this.forms.updateAdministrativeAreas(country, options, addressField, this.dynamicAdministrativeArea);
  }

  submit(addAnother: boolean = false) {
    if (this.currentURL[4] && this.currentURL[4] === 'edit') {
      const body = this.forms.prepareFormValues(this.formFields, this.form);
      console.log(body);
      console.log(this.loadedEntity);
      this.update(body);
    } else if (!addAnother) {
      console.log('no entity loaded');
      this.create(this.entityType, this.entityBundle);
    } else if (addAnother) {
      this.create(this.entityType, this.entityBundle, true, true);
    }
  }

  async create(entityType: string, entityBundle: string, showLoading: boolean = true, addAnother: boolean = false) {
    if (showLoading) {
      this.loading = await this.loadingCtrl.create({});
      await this.loading.present();
    }
      switch(entityType) {
        case 'user': {
          if (entityBundle === 'register') {
            const cleanedValues: any = this.userService.structureEntity(this.form.value, ['phone_number', 'user_name', 'mail', 'pass']);
            const newUser = new UserAccount(cleanedValues.user_name, cleanedValues.mail, cleanedValues.pass, cleanedValues.phone_number);
            console.log(newUser);
            const newAccount = await this.entityService.createEntity('user/register', newUser).then(async res => {
              console.log(res);
              if (this.loading) {
                await this.loading.dismiss();
                this.loading = null;
              }
              if (!res.uid) {
                this.message.presentAlert('Error', 'There was a problem. Error Code: ' + res.statusText);
              } else {
                return res;
              }
            }).catch(error => {
              this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
            });
            if (newAccount?.uid) {
              await this.userService.getUserById(newAccount.uid).then(res => {
                console.log(res);
                this.message.presentAlert('Success', `User account has been created.`);
                this.router.navigate([`node/delegate_registration/${res.uid}/create`], {replaceUrl: true});
              });
              // const loginBody = {
              //   username: this.form.value.name,
              //   password: this.form.value.pass
              // };
              // await this.user.login('session', loginBody, true, environment.checkInType.entityType, environment.checkInType.bundle);
            }
          }
          if (entityBundle === 'login') {
            await this.user.login('session', this.form.value, true, environment.checkInType.entityType, environment.checkInType.bundle);
          }
          break;
        }
        case 'form':
        case 'node': {
          this.form.value.author = this.author ? this.author : environment.appSettings.AppUserUID;
          this.form.value.type = entityBundle;
          this.forms.prepareFormValues(this.formFields, this.form);
          this.entityService.createEntity('node', this.form.value).then(async res => {
            console.log(res);
            if (this.loading) {
              await this.loading.dismiss();
              this.loading = null;
            }
            this.message.presentAlert('Success', `${res.title} has been created.`);
            if (entityBundle === 'delegate_registration') {
              this.entityService.retrieveEntity('commerce_line_item', res.linked_line_item.id).then(lineItem => {
                console.log(lineItem);
                if (!addAnother) {
                  this.router.navigate([`pay/${lineItem.order_id}`], {replaceUrl: true});
                } else {
                  this.reset();
                }
              }).catch(err => err);
            }
            if (entityBundle === 'church') {
              this.pageTitle = 'Create Delegate';
              this.router.navigate([`node/delegate_registration/${res.author.id}/create`], {replaceUrl: true});
              this.initialize('delegate_registration');
            }
          }, error => {
            this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
          });
          break;
        }
        case 'commerce_customer_profile': {
          this.form.value.type = entityBundle;
          this.form.value.status = 1;
          this.commerceCustomerProfileService.createProfile(this.form.value).then(res => {
            this.reset();
          }, error => {
            this.message.presentAlert('Error', 'There was a problem. Error Code: ' + error.message);
          });
          break;
        }
        default: {
          break;
        }
    }
  }

  async update(body: any) {
    console.log(body);
    if (this.entityType === 'user') {
      this.message.showLoading('Updating your information', false, 1500);
      await this.userService.updateUser(body);
      const session: SystemConnection = await this.storage.get('session');
      await this.user.getAPIUser('session', session).then(res => {
        this.loadedEntity = res.user;
      });
      return this.navigateToPage('');
    } else {
      const updatedEntity = await this.entityService.updateEntity(this.entityType, body, this.loadedEntity.nid)
      .then(res => {
        this.message.presentToast('Updated', 2000);
        return res;
      })
      .catch(err => {
        console.error('Error Message: ', err);
        this.message.presentAlert('Error updating', 'There was a problem updating' +
        this.loadedEntity.nid + '. Error Code: ' + err.status + ':' + err.statusText);
        return throwError(err);
      });
      return updatedEntity;
    }
  }

  async delete() {
    this.message.showLoading('Deleting...', true, 2000);
    const deletedEntity = await this.entityService.deleteEntity(this.entityType, this.loadedEntity.nid);
  }

  reset() {
    this.form = this.fb.group({});
    this.formFields = null;
    this.initialize();
  }

  cancel() {
    this.modalController.dismiss();
  }

}
