/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { AlertController } from '@ionic/angular';
import { FlagServiceCustom } from './flag.service';
import { Pager } from './drupal7/models/forms';
import { EntityService, MainService, ViewService } from './drupal7/drupal7-services.module';
import { ViewOptions } from './drupal7/models';
import { throwError } from 'rxjs';
import { DrupalFormControlObject } from './drupal7/models/form-controls';
import { QRCode } from './drupal7/models/qrcode';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceCustom {

  constructor(
    private viewService: ViewService,
    private alertCtrl: AlertController,
    public message: MessagesService,
    private entityService: EntityService,
    public flag: FlagServiceCustom,
    private mainService: MainService,
    public storage: Storage
    ) {}


  async findRegistration(url: string, options: ViewOptions, autoFlag: boolean = false) {
    console.log(url);
    return this.viewService.getView(url, options).then((res: any) => {
      console.log(res);
      const result = [];
      const items = [];
      if (res.pager?.page === -1) {
        return;
      }
      if (res.name === 'TimeoutError') {
        const returnErr = {
          headers: null,
          pager: null,
          paidCount: null,
          results: null,
          noResultsData: true
        };
        return returnErr;
      }
      if (res[0]) {
        Object.keys(res).map(key => key !== 'pager' ? items.push(res[key]) : null);
        for (const item of items) {
          result.push(item);
        }

        // Remove the duplicates from the array because they can't be removed from the api
        // because the data from the api is being sorted by order id, line item id, and then nid.
        // This is sorted this way on from the api because the badges are sorted this way. This makes
        // it so the data from the api and the physical badges are grouped and sorted the same way.

        // Need a different way of getting the page summary to display the correct number because we want to remove
        // duplicates from the final result.
        // const uniqueResult: Array<FormData> = Array.from(result.reduce((m, t) => m.set(t.nid, t), new Map()).values());
        const uniqueResult = result;
        let pager: Pager;
        if (res.pager) {
          pager = res.pager;
          pager.links = Array.from(new Array(res.pager?.pages), (x,i) => i);
        }
        if (autoFlag) {
          this.flag.autoflag(result);
        }
        this.mainService.removeProps(uniqueResult,['pager']);
        this.setupQRCode(uniqueResult);
        this.setupUniqueOrders(uniqueResult);
        this.setupChildcare(uniqueResult);
        // this.setupMultipleBadges(uniqueResult);
        const paidCount = this.setupPaidCount(uniqueResult);
        const headers = Object.keys(uniqueResult[0]).map(key => key);
        const returnedResult = {
          headers,
          pager,
          paidCount,
          results: uniqueResult,
          noResultsData: false
        };
        return returnedResult;
      }
    });
  }

  setupChildcare(data: any) {
    for (const entity of data) {
      this.mainService.convertObjToArray(entity, environment.arrayFields);
    }
    return data;
  }

  setupMultipleBadges(data: any) {
    for (const entity of data) {

    }
  }

  setupQRCode(data: any) {
    for (const entity of data) {
      entity.qr_codes = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}^group=${entity.group_manager}?`;
          const qrCodeData: QRCode = {
            id: entity.nid,
            line_item_id: order.line_item_id,
            order_id: order.order_id,
            uid: order.uid,
            group_manager: entity.group_manager,
            qr_code_value: qrCodeDataValue
          };
          entity.qr_codes.push(qrCodeData);
        }
      }
    }
    return data;
  }

  setupBadgeType(data: any) {
    for (const entity of data) {
      entity.class = 'regular';
      entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-regular.svg) !important;';
      // if (environment.badgeType1.indexOf(entity.delegate_type) > -1) {
      //   entity.class = 'roundtable';
      //   entity.badge_template = 'background-image: url(../../assets/imgs/slc-asia-card-badge-template-roundtable.svg) !important;';
      // }
    }
    return data;
  }

  getDelegateType(entity: any): string {
    let val = entity.delegate_type_value;
    Object.keys(environment.delegateTypes).map(key => {
      const value = environment.delegateTypes[key];
      if (value.types.indexOf(entity.delegate_type) !== -1) {
        val = value.value;
      }
      if (value.types.indexOf(entity.nid) !== -1) {
        val = value.value;
      }
    });
    return val;
  }

  getGroupLabel(entity: any): string {
    const val = entity.field_meeting_location;
    return environment.badgeGroups[val];
  }

  setupBadgeControls(data: any) {
    for (const entity of data) {
      const firstNameLength = entity.contact_first_name.length;
      const lastNameLength = entity.contact_last_name.length;
      entity.contact_first_name_size = '20';
      entity.contact_last_name_size = '20';
      if (firstNameLength > 20) {
        entity.contact_first_name_size = '13';
      } else if (firstNameLength > 18) {
        entity.contact_first_name_size = '14';
      } else if (firstNameLength > 15) {
        entity.contact_first_name_size = '15';
      } else if (firstNameLength > 13) {
        entity.contact_first_name_size = '17';
      }
      if (lastNameLength > 15) {
        entity.contact_last_name_size = '13';
      } else if (lastNameLength > 11) {
        entity.contact_last_name_size = '17';
      }

      entity.church_title_size = '10';
      entity.country_size = '10';
      // entity.seating_assignment_original = entity.seating_assignment;
      entity.church_title = entity.linked_church_value;
      entity.church_title_original = entity.linked_church_value;
    }
    return data;
  }

  setupUniqueOrders(data: any) {
    console.log(data);
    for (const entity of data) {
      entity.unique_orders = [];
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const index = entity.unique_orders.map((o) => o.order_id).indexOf(order.order_id);
          if (index === -1) {
            entity.unique_orders.push(order);
          }
        }
      }
    }
    return data;
  }

  setupPaidCount(data: any) {
    let paidCount = 0;
      data.map(e => {
        paidCount += e.flag_delegate_paid ? 1 : 0;
      });
      return paidCount;
  }

  async presentUpdateEmailAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: Array<any>) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Email Address',
      backdropDismiss: false,
      message: `Please confirm the email address for ${entity.contact_first_name} ${entity.contact_last_name} or continue if they have no email address.`,
      inputs,
      buttons: [
        {
          text: 'Continue Check-in',
          role: 'cancel'
        }, {
          text: 'Update and Check-in',
          cssClass: 'primary',
          role: 'submit'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        this.message.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.entityService.updateEntity(entityType, body, entity.nid).then(res => res[field]);
      }
      if (form.role === 'cancel') {
        return entity[field];
      }
    }, err => throwError(err));
  }


  async presentUpdateFieldAlert(entityType: string, entity: any, formField: DrupalFormControlObject, field: any, inputs: Array<any>) {
    console.log(inputs);
    console.log(formField);
    const alert = await this.alertCtrl.create({
      header: formField.label,
      backdropDismiss: true,
      message: 'Enter the ' + field + ' for ' +  entity.contact_first_name + ' ' + entity.contact_last_name,
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      console.log(form);
      console.log(formField);
      if (form.role === 'submit') {
        const body = {};
        body[field] = form.data.values[field];
        if (form.data.values[field] === undefined) {
          body[field] = form.data.values;
        }
        console.log(body);
        this.message.showLoading('Updating ' + formField.label + ' from ' + entity[field] + ' to ' + body[field], false, 3000);
        return await this.entityService.updateEntity(entityType, body, entity.nid).then(res => res[field]);
      }
    }, err => throwError(err));
  }

  async presentCreateEntityAlert(entityType: string, formFields: DrupalFormControlObject[], inputs: Array<any>) {
    console.log(inputs);
    console.log(formFields);
    const alert = await this.alertCtrl.create({
      header: 'Create ' + entityType,
      backdropDismiss: true,
      message: 'Enter the data',
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        }, {
          text: 'Update',
          cssClass: 'primary',
          role: 'submit'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      console.log(form);
      console.log(formFields);
      if (form.role === 'submit') {
        console.log(form.data.values);
        this.message.showLoading('Creating ' + entityType, false, 3000);
        // return await this.entityService.createEntity(entityType, form.data.values).then(res => res);
      }
    }, err => throwError(err));
  }


}


