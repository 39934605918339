/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { FormInlineComponent } from 'src/app/form-inline/form-inline.component';
import { MainService, VboService, ViewService } from 'src/app/services/drupal7/drupal7-services.module';
import { SystemConnection, ViewOptions } from 'src/app/services/drupal7/models';
import { FlagIonic } from 'src/app/services/drupal7/models/flag';
import { FormFromJSON, Pager } from 'src/app/services/drupal7/models/forms';
import { BulkEditEntity, ToggleStatusEntity } from 'src/app/services/drupal7/models/vbo';
import { EntityServiceCustom } from 'src/app/services/entity.service';
import { FlagServiceCustom } from 'src/app/services/flag.service';
import { FormsService } from 'src/app/services/forms.service';
import { MessagesService } from 'src/app/services/messages.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserServiceCustom } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment.prod';
import { ManageRegistrationsComponent } from '../manage-registrations/manage-registrations.component';

@Component({
  selector: 'app-manage-churches',
  templateUrl: './manage-churches.component.html',
  styleUrls: ['./manage-churches.component.scss'],
})
export class ManageChurchesComponent implements OnInit {

  // Schema Properties
  entityType = 'node';
  bundle = 'church';
  viewName = 'admin_delegates_filter_all';
  viewDisplay = 'default';
  contextualFilters = '';
  fieldName = 'linked_church';

  // Data
  churches: Array<Church>;
  publishedChurches: Array<any>;
  unpublishedChurches: Array<any>;
  headers: Array<string>;
  noResultsData = true;

  // Auth
  session: SystemConnection;
  // appUser: SystemConnection;
  requestOptions: any;
  flags: Array<FlagIonic>;
  checkInOutFlags: Array<FlagIonic>;

  // Data Properties
  paidCount = 0;
  pager: Pager;
  sortDirection = 0;
  sortKey = null;
  formSchema: FormFromJSON;

  // Filters
  inputOrderID: string | number;
  scannedInput: string | number;
  resultsCount = 50;
  itemsPerPage = [5, 10, 25, 50];
  bulkEdit = true;
  edit: Array<any>;
  title = '';
  pastor = '';
  status = 'All';
  hasDelegates = 'All';
  country = 'All';
  countries: Array<any>;

  // Bulk Operations
  toChurch: string;

  constructor(
    private mainService: MainService,
    private viewService: ViewService,
    private vbo: VboService,
    private user: UserServiceCustom,
    private storage: StorageService,
    private flag: FlagServiceCustom,
    private message: MessagesService,
    private forms: FormsService,
    private modalController: ModalController
  ) { }

  async ngOnInit() {
    this.user.currentSession.subscribe(session => this.session = session);
    // this.appUser = await this.user.getAppUser();
    this.formSchema = await this.forms.getForm(this.session, this.entityType, this.bundle);
    this.message.showLoading('Loading...', false, 1500);
    this.countries = this.formSchema.form_fields.filter(o => o.field_name === 'church_address')[0].fields.filter(x => x.field_name === 'country')[0].options;
    this.get();
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value.localeCompare(b.value);

  search(input: string = '', showLoading: boolean = true, autoflag: boolean = false) {
      if (showLoading) {
        this.message.showLoading('Searching for ' + input, false, 1500);
      }
      this.inputOrderID = '';
      this.get('', autoflag);
  }


  searchCountry(input: string) {
    return this.countries.filter(x => {
      console.log(x);
      if (x.value.toLowerCase().indexOf(input.toLowerCase()) === -1) {
        x.hidden = true;
      } else {x.hidden = false;}
    });
  }


  filter() {
    this.message.showLoading('Filtering results...', false, 500);
    this.get();
  }

  resetSearch() {
    this.title = '';
    this.pastor = '';
    this.status = 'All';
    this.hasDelegates = 'All';
    this.country = 'All';
    this.inputOrderID = '';
    this.churches = null;
    this.publishedChurches = null;
    this.unpublishedChurches = null;
    this.message.showLoading('Resetting', false, 300);
    this.get();
  }

  sortBy(key) {
    this.sortKey = key;
    this.sortDirection++;
    this.sort();
  }

  sort() {
    if (this.sortDirection === 1) {
      this.churches = this.churches.sort((a, b) => a[this.sortKey].localeCompare(b[this.sortKey]));
    } else if (this.sortDirection === 2) {
      this.churches = this.churches.sort((a, b) => b[this.sortKey].localeCompare(a[this.sortKey]));
    } else {
      this.sortDirection = 0;
      this.sortKey = null;
      this.churches = this.churches.sort((a, b) => b.nid.localeCompare(a.nid));
    }
  }

  toggleBulkEdit() {
    this.bulkEdit = !this.bulkEdit;
    for (const row of this.churches) {
      row.edit = false;
    }
  }

  bulkEditEntities() {
    const ids = this.churches.filter(o => o.edit && o.delegate_registrations);
    console.log(ids);
  }

  toggleSelectAll(checked) {
    const val = checked ? false : true;
    this.churches.map(o => o.delegate_registrations ? o.edit = val : o.edit = false);
  }

  checkForEdits() {
    let ids = this.churches.map(o => {if (o.edit && o.delegate_registrations) {return o.nid;}});
    ids = ids.filter(id => id);
    if (ids.length) {
      return true;
    } else {return false; }
  }

  pagerChange(page: string = '') {
    this.message.showLoading('Loading page ' + page, false, 1500);
    this.get(page);
  }

  async get(page: string = '', autoFlag: boolean = false) {
    this.churches = null;
    this.unpublishedChurches = null;
    this.publishedChurches = null;

    const options: ViewOptions = {
      display_id: 'api_churches',
      filters: {
        status: this.status,
        title: this.title,
        pastor_value: this.pastor,
        has_delegates: this.hasDelegates,
        church_address_country: this.country,
        page,
        items_per_page: this.resultsCount
      }
    };

    const session = await this.storage.get('session');
    return this.viewService.getView('churches', options).then( async (res: any) => {
      console.log(res);
      const result = [];
      const items = [];
      if (res[0]) {
        this.headers = Object.keys(res[0]).map(key => key);
        Object.keys(res).map(key => key !== 'pager' ? items.push(res[key]) : null);
        for (const item of items) {
          result.push(item);
        }

        // Remove the duplicates from the array because they can't be removed from the api
        // because the data from the api is being sorted by order id, line item id, and then nid.
        // This is sorted this way on from the api because the badges are sorted this way. This makes
        // it so the data from the api and the physical badges are grouped and sorted the same way.

        // Need a different way of getting the page summary to display the correct number because we want to remove
        // duplicates from the final result.
        // const uniqueResult: Array<FormData> = Array.from(result.reduce((m, t) => m.set(t.nid, t), new Map()).values());
        const uniqueResult = result;

        if (res.pager) {
          this.pager = res.pager;
          this.pager.links = Array.from(new Array(res.pager?.pages), (x,i) => i);
        }
        // this.manualEntry = true;
        this.noResultsData = false;
        if (autoFlag) {
          const flags: Array<FlagIonic> = await this.storage.get('flags_'+environment.checkInType.bundle);
          for (const flag of flags) {
            if (session.user.flag_access.flag[flag.name] && flag.flag_on_scan && flag.status) {
              for (const entity of uniqueResult) {
                if (!entity['flag_'+flag.name]) {
                  this.flagEntity(entity, flag, entity['flag_'+flag.name]);
                  // await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
                }
              }
            }
          }
        }
        this.mainService.removeProps(uniqueResult,['pager']);
        this.unpublishedChurches = uniqueResult.filter(o => !o.status);
        this.publishedChurches = uniqueResult.filter(o => o.status);
        return this.churches = uniqueResult;
      }
      if (res.pager?.page === -1) {
        return this.noResultsData = true;
      }
    });
  }

  async showLinkedRegistrations(church: Church) {
    const modal = await this.modalController.create({
      component: ManageRegistrationsComponent,
      cssClass: 'fullscreen',
      componentProps: {
        church,
        requestOptions: this.requestOptions,
        session: this.session,
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.values) {
      console.log(res);
    }
  }


  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    const flagMessage = 'Flagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const unflagMessage = 'Unflagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const session = await this.storage.get('session');
    const newFlagStatus = await this.flag.flagEntity(item, flag, flagValue, session, flagMessage, unflagMessage);
    item['flag_'+flag.name] = newFlagStatus;
  }

  async switchChurch() {
    let ids = this.churches.map(o => {if (o.edit && o.delegate_registrations) {return +o.nid;}});
    ids = ids.filter(id => id);
    const body: BulkEditEntity = {
      new_entity_id: +this.toChurch,
      entity_type: this.entityType,
      bundle: this.bundle,
      view_name: this.viewName,
      view_display: this.viewDisplay,
      contextual_filters: [],
      field_name: this.fieldName,
      ids
    };
    console.log(body);
    if (body.ids.length && body.new_entity_id) {
      this.message.showLoading('Migrating registrations', false, 1500);
      return this.vbo.bulkEdit(body).then(res => {
        console.log(res);
        this.get();
        return res;
      });
    } else {
      this.message.presentAlert('Missing data', 'Please select an editable entity or assign a new entity');
    }
  }

  checkStatus(church: any) {
    if (!church.delegate_registrations && church.status) {
      return 'danger';
    }
    if (church.status) {
      return 'success';
    }
    if (!church.delegate_registrations) {
      return 'warning';
    }
  }

  async toggleStatus() {
    const entities = {};
    this.churches.map(o => {if (o.edit) {return entities[o.nid] = {status: this.status ? 1 : 0};}});
    const body: ToggleStatusEntity = {
      entities,
      entity_type: this.entityType,
      bundle: this.bundle
    };
    console.log(body);
    if (body.entities) {
      this.message.showLoading('Changing the status', false, 1500);
        return this.vbo.toggleStatus(body).then(res => {
        console.log(res);
        // this.search(this.inputData, false);
        return res;
      });
    } else {
      this.message.presentAlert('Missing data', 'Please select an editable entity or assign a new entity');
    }
  }

  async editField(entity: any, key: string) {
    const formField = this.forms.getFieldControl(key, this.formSchema.formGroup, this.formSchema.form_fields);
    const modal = await this.modalController.create({
      component: FormInlineComponent,
      cssClass: 'edit-entity',
      componentProps: {
        entityType: entity.entity_type,
        entityBundle: entity.bundle,
        requestOptions: this.requestOptions,
        loadedEntity: entity,
        entityForm: this.formSchema.formGroup,
        formField,
        pageTitle: `${entity.contact_first_name} ${entity.contact_last_name} (${entity.nid})`,
        modalMode: true,
        session: this.session,
        isEntityReferenceField: formField.type === 'entityreference' ? true : false,
        nestedFieldGroups: this.formSchema.nestedFieldGroups
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.values[key]) {
      console.log(res);
      entity[key] = res.data.values[key];
      if (key === 'contact_first_name' ||
          key === 'contact_last_name' ||
          key === 'linked_church' ||
          key === 'delegate_type') {
            const flag: FlagIonic = {
              fid: 4,
              entity_type: 'node',
              global: 1,
              name: 'badge_printed',
              label: 'Badge Printed',
              title: 'Badge Printed'
            };
            if (entity['flag_'+flag.name]) {
              this.message.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
              this.flagEntity(entity, flag, entity['flag_'+flag.name]);
            }
      }
    }
  }

}

export interface Church {
  nid: string;
  delegate_registrations: number;
  title: string;
  pastor: string;
  church_address_thoroughfare: string;
  church_address_locality: string;
  church_address_administrative_area: string;
  church_address_country: string;
  church_email: string;
  status: number;
  church_address_dependent_locality: string;
  church_address_postal_code: number;
  church_address_premise: string;
  type: string;
  entity_type: string;
  edit: boolean;
}
