/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ModalController } from '@ionic/angular';
import { MessagesService } from '../services/messages.service';
import { formatCurrency } from '@angular/common';
import { FormsService } from '../services/forms.service';
import { environment } from 'src/environments/environment.prod';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { FlagServiceCustom } from '../services/flag.service';
import { FlagIonic } from '../services/drupal7/models/flag';
import { APICommerceOrder, CommercePaymentTransactionStatus } from '../services/drupal7/models/commerce';
import { ViewOptions } from '../services/drupal7/models';
import { ViewService } from '../services/drupal7/drupal7-services.module';

@Component({
  selector: 'app-order',
  templateUrl: 'order.component.html',
  styleUrls: ['order.component.scss']
})
export class OrderComponent implements OnInit {
  @Input() order: APICommerceOrder = null;
  @Input() appUser: object;
  @Input() session = null;
  @Input() requestOptions: any;

  flags: Array<FlagIonic>;
  showMoreDetails = false;

  constructor(
    private message: MessagesService,
    private flag: FlagServiceCustom,
    private viewService: ViewService,
    private forms: FormsService,
    private modalController: ModalController,
    public storage: Storage
    ) {
    console.log('listening for order scan');
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
      // this.swipe(evt);
    });
  }

  async ngOnInit() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
    this.buildOrder(this.order);
  }

  bulkFlagCheckIn(e: any) {
    const flag: FlagIonic = {
      fid: 4,
      entity_type: 'node',
      global: 1,
      name: 'checked_in',
      label: 'Conference Check-in',
      title: 'Conference Check-in'
    };
    for (const lineItem of this.order.commerce_line_items) {
      if (e.detail.checked && !lineItem['flag_'+flag.name] && lineItem.flag_delegate_paid) {
        this.flagEntity(lineItem, flag, lineItem['flag_'+flag.name]);
      }
      if (!e.detail.checked && lineItem['flag_'+flag.name] && lineItem.flag_delegate_paid) {
        this.flagEntity(lineItem, flag, lineItem['flag_'+flag.name]);
      }
    }
  }

  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    const flagMessage = 'Flagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const unflagMessage = 'Unflagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const session = await this.storage.get('session');
    const newFlagStatus = await this.flag.flagEntity(item, flag, flagValue, session, flagMessage, unflagMessage);
    item['flag_'+flag.name] = newFlagStatus;
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }

  async reloadOrder(orderID: string | number) {
    this.message.showLoading('Refreshing the order... ', false, 2000);
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [orderID],
    };
    this.viewService.getView('registration/commerce-order', options).then((res: any) => {
      console.log(res);
      this.order = res[0];
      this.buildOrder(this.order);
    });
  }

  buildOrder(res: APICommerceOrder) {
      // Setup the line item total
      if (res.commerce_line_items?.length) {
        let lineItemsTotal = 0;
        for (const lineItem of res.commerce_line_items) {
            lineItemsTotal += lineItem.commerce_total;
            // Count the number of orders that are referenced per delegate
            if (lineItem.referenced_delegate_embed?.length) {
              for (const delegate of lineItem.referenced_delegate_embed) {
                delegate.referenced_orders_array = [];
                if (delegate.referenced_orders?.length) {
                  for (const order of delegate.referenced_orders) {
                    delegate.referenced_orders_array.push(order.order_id);
                  }
                }
              }
            }
        }
        res.commerce_line_items_amount = lineItemsTotal;
        res.commerce_line_items_amount_formatted = formatCurrency(parseFloat(res.commerce_line_items_amount.toFixed(2)) / 100, 'en-US', '$');

      }

      // Setup the billing info
      if (res.commerce_customer_profiles?.length) {
        res.commerce_customer_profiles = this.forms.convertObjectToArray(res.commerce_customer_profiles);
      }
      // Setup the payment transactions
      if (res.commerce_payment_transactions?.length) {
        let transactionsTotal = 0;
        for (const transaction of res.commerce_payment_transactions) {
          if (transaction.status === CommercePaymentTransactionStatus.success) {
            const amount = Number(transaction.amount.toString().replace(/[^0-9.-]+/g,''));
            transactionsTotal += amount;
          }
        }
        res.commerce_order_payments_amount = +transactionsTotal.toFixed(2).replace('.', '');
        res.commerce_order_payments_amount_formatted = formatCurrency(parseFloat(res.commerce_order_payments_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      if (res.order_owner?.length) {
        for (const owner of res.order_owner) {
          if (owner.rid) {
            const roles = owner.rid.split(',');
            console.log(roles);
            Object.keys(roles).map(key => {
              owner.hasRegularRole = environment.user.roleTypes.RegularRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
            Object.keys(roles).map(key => {
              owner.hasChurchRole = environment.user.roleTypes.ChurchRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
          }
        }
      }


    this.order = res;
    console.log(this.order);
    this.groupOrder();
    this.message.presentToast('Order loaded', 1000);
    return this.order;
  }

  async groupOrder() {
    const result: object = {};
    for await (const lineItem of this.order.commerce_line_items) {
      result[lineItem.nid] = this.order.commerce_line_items.filter(o => o.nid === lineItem.nid);
    }
    console.log(result);
    this.order.commerce_line_items_grouped = Object.values(result).sort((a, b) => a[1] - b[1]);
    console.log(this.order);
    return this.order;
  }

  cancelModal() {
    this.modalController.dismiss();
  }

}
