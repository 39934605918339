import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/drupal7/drupal7-services.module';
import { MessagesService } from './services/messages.service';
import { StorageService } from './services/storage.service';
import { Capacitor } from '@capacitor/core';
import { FirebaseCloudMessagesService } from './services/firebase-cloud-messages.service';
import { UserServiceCustom } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @Input() session: any;
  @Output() sessionChange: EventEmitter<any> = new EventEmitter();

  constructor(private storage: StorageService,
    private fcmService: FirebaseCloudMessagesService,
    public router: Router,
    private messages: MessagesService,
    private userService: UserServiceCustom) {
    this.init();
  }

  async init() {
    await this.storage.init();
    this.userService.currentSession.subscribe(session => this.session = session);
    // Trigger the push setup
    if (Capacitor.getPlatform() !== 'web') {
      this.fcmService.initPush();
    }
    if (this.session) {
      const activeSession = await this.userService.getAPIUser('session', await this.storage.get('session')).then(res => res, err => err);
      if (activeSession.status === 401) {
        this.messages.presentToast('Please login to continue.', 1500, 0, 'top', 'danger');
        this.userService.changeSession(null);
      } else if (activeSession.status === 400) {
        this.userService.changeSession(null);
      }
    } else {
      this.userService.changeSession(null);
    }
  }


}

