/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../services/user.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { MessagesService } from '../services/messages.service';
import { IonSearchbar, LoadingController, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { PhotoService } from '../services/photo.service';
import { EntityServiceCustom } from '../services/entity.service';
import { FlagServiceCustom } from '../services/flag.service';
import { CodeScanService } from '../services/codescan.service';
import { FormsService } from '../services/forms.service';
import { FormInlineComponent } from '../form-inline/form-inline.component';
import { FlagIonic } from '../services/drupal7/models/flag';
import { FormFromJSON, FoundRegistrations, Pager } from '../services/drupal7/models/forms';
import { DrupalFormControlObject } from '../services/drupal7/models/form-controls';
import { FileAttachToEntity } from '../services/drupal7/models/file';
import { FileService } from '../services/drupal7/drupal7-services.module';
import { ViewOptions } from '../services/drupal7/models';
import { AssetsService } from '../services/assets.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
})
export class BadgesComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  @Input() session: any;

  flags: Array<FlagIonic>;
  headers: Array<string>;
  data: Array<FormData>;
  formFilters: Array<any>;

  noResultsData = true;
  inputData: string | number;
  scannedInput: string | number;
  pager: Pager;
  sortDirection = 0;
  sortKey = null;
  bulkEdit = false;
  editObj = {};
  resultsCount = 10;
  itemsPerPage = [5, 10, 20, 40, 60, 100];
  manualEntry = false;
  leftPage = false;
  loading: HTMLIonLoadingElement;

  showFilters = false;
  filterPaid = 'All';
  filterCheckedIn = '0';
  filterBadgePrinted = '0';
  // filterBadgePrePrinted = '0';
  filterLocation = 0;
  filterGroup = 0;
  filterFirstName = '';
  filterDelegateType: '';
  formSchema: FormFromJSON;
  cardBadge = true;

  constructor(
    private flag: FlagServiceCustom,
    private assetsService: AssetsService,
    private forms: FormsService,
    private entity: EntityServiceCustom,
    private codeScan: CodeScanService,
    private platform: Platform,
    public router: Router,
    public storage: Storage,
    private photoService: PhotoService,
    private barcodeScanner: BarcodeScanner,
    private loadingCtrl: LoadingController,
    private modalController: ModalController,
    private user: UserServiceCustom,
    private fileService: FileService,
    public message: MessagesService
    ) {
      console.log('listening for badge scan');
      this.leftPage = false;
      document.addEventListener('keypress', (evt: KeyboardEvent) => {
          this.scan(evt);
      }, true);

      this.initializeData();
   }

   @HostListener('window:beforeunload')
      ngOnDestroy() {
        console.log('badges page destroyed');
        this.leftPage = true;
        document.removeEventListener('keypress', (evt: KeyboardEvent) => {
      }, true);
    }

   async scan(event) {
    if (!this.manualEntry && !this.leftPage) {
      const res = await this.codeScan.scan(event);
      if (res?.id) {
          this.loadDelegateAndFlagAsPrinted(+res.id);
      }
    }
  }

  async loadDelegateAndFlagAsPrinted(id: number = 0) {
    const session = await this.storage.get('session');
    const options: ViewOptions = {
      display_id: 'app_registration_badges',
      filters: {
        id,
        items_per_page: this.resultsCount
      }
    };

    this.entity.findRegistration('registration/badges', options).then(async (res) => {
      this.headers = res.headers;
      this.pager = res.pager;
      this.noResultsData = res.noResultsData;
      this.entity.setupBadgeType(res.results);
      this.entity.setupBadgeControls(res.results);
      this.data = res.results;
      const flags: Array<FlagIonic> = await this.storage.get('flags_by_type').then(foundFlags => foundFlags?.badgeFlags);
      for (const flag of flags) {
        if (session.user.flag_access.flag[flag.name]) {
          for (const entity of this.data) {
            if (!entity['flag_'+flag.name] && flag.name === 'badge_printed') {
              this.flag.setupFlagMessageAndFlag(entity, flag, entity['flag_'+flag.name]);
            }
          }
        }
      }
    });
  }

   async ngOnInit() {
    this.formFilters = await this.assetsService.getAssetItems(`form-filters/${environment.checkInType.bundle}`);
   }

  async initializeData() {
    this.session = await this.storage.get('session');
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
    const checkSelf = await this.storage.get('selfCheckIn');
    if (!checkSelf) {
      if (this.session) {
        this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
        this.formSchema = await this.forms.getForm(this.session, environment.checkInType.entityType, environment.checkInType.bundle);
        if (!this.flags) {
          this.setFlagAccess(false);
          this.message.showLoading('Setting up your data', false, 2000);
        }
      } else {
        this.session = await this.user.loginForm('session', environment.checkInType.entityType, environment.checkInType.bundle);
        if (this.session) {
          this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
          if (!this.flags) {
            this.setFlagAccess(false);
            this.message.showLoading('Setting up your data', false, 2000);
          }
        }
      }
    }
  }

  sessionChange(e) {
    this.session = e;
  }
  async flagChange() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
  }

  async segmentChanged(val: any) {
    this.router.navigate([val], { replaceUrl: true });
  }

  scanQRCode() {
    if (this.platform.is('capacitor')) {
      this.barcodeScanner.scan({showFlipCameraButton: true, preferFrontCamera: true}).then(barcodeData => {
        if (barcodeData.text && !barcodeData.cancelled) {
          this.message.showLoading('Searching for ' + barcodeData.text, false, 1500);
          this.get(barcodeData.text, '', true);
        }
       }).catch(err => {
           console.log('Error', err);
       });
    } else {
      this.scannedInput = '';
      this.searchInput.setFocus();
    }
  }

  async addPhoto(entity: any, fieldName: string) {
    const fileName = `${entity.contact_first_name} ${entity.contact_last_name}`.trim().toLowerCase().replace(' ', '-');
    const newPhoto = await this.photoService.takePhoto(fileName);
    this.message.showLoading('Submitting file...', false, 2000);
    const fileBody: FileAttachToEntity = {
      file: newPhoto,
      entity_id: entity.nid,
      entity_type: entity.entity_type,
      field_name: fieldName,
    };
    const newFile = await this.fileService.attachFilesToEntity(fileBody).then(res => res).catch(err => err);
    entity[fieldName].src = newFile.uri_full;
    if (newFile?.fid) {
      this.message.presentToast('Photo added', 2000);
    } else {
      this.message.presentAlert('Error creating file', 'Error Code: ' + newFile.status + ':' + newFile.statusText);
    }
  }

  async updateField(entity: any, key: string) {
    const form = await this.forms.getForm(this.session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      // for (const g of form.formFields) {
        const formField: DrupalFormControlObject = form.form_fields.filter(o => o.field_name === key)[0];
        // if (g.fields) {
        //   formField = g.fields.filter(o => o.field_name === key)[0];
        // }
        if (formField !== undefined) {
          const inputs = [];
          const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
          if (formField.options) {
            for (const option of formField.options) {
              inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
            }
          } else if (formField.ionic_type === 'boolean') {
            inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
          } else {
            inputs.push(
              {
                name: key,
                type: formField.type,
                id: key,
                placeholder: '',
                value: entity[key]
              }
            );
          }
          this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
            if (val) {
             return entity[key] = val;
            }
          });
        }
      // }
      return true;
    } else {
      return false;
    }
  }

  getFieldControl(key: string) {
    console.log(this.formSchema);
    if (this.formSchema.formGroup.controls[key]) {
      const formField: DrupalFormControlObject = this.formSchema.form_fields.filter(o => o.field_name === key)[0];
      if (formField !== undefined && formField.field_name === key) {
        return formField;
      } else {
        for (const g of this.formSchema.form_fields) {
          if (g.fields) {
            const nestedFormField: DrupalFormControlObject = g.fields.filter(o => o.field_name === key)[0];
            console.log(nestedFormField);
            if (nestedFormField.field_name === key) {
              return nestedFormField;
            }
          }
        }
      }
    }
  }

  async editField(entity: any, key: string) {
    console.log(key);
    const fieldElement: DrupalFormControlObject = this.getFieldControl(key);
    console.log(fieldElement);
    const modal = await this.modalController.create({
      component: FormInlineComponent,
      cssClass: 'edit-entity',
      componentProps: {
        entityForm: this.formSchema.formGroup,
        fieldElement,
        entityType: entity.entity_type,
        entityBundle: entity.bundle,
        session: this.session,
        loadedEntity: entity,
        isEntityReferenceField: fieldElement.type === 'entityreference' ? true : false,
        nestedFieldGroups: this.formSchema.nestedFieldGroups
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.values[key]) {
      entity[key] = res.data.values[key];
      if (key === 'linked_church') {
        entity.church_title = res.data.values.entity.title;
      }
      this.flag.unflagBadgePrinted(entity, key, res.data?.values[key]);
    }
  }

  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    this.flag.setupFlagMessageAndFlag(item, flag, flagValue);
  }

  async setFlagAccess(showLoading: boolean = true) {
    const session = await this.storage.get('session');
    if (showLoading) {
      this.message.presentToast('Refreshing your account access.', 2000);
    }
    const result = await this.user.setFlagAccess(session, environment.checkInType.entityType, environment.checkInType.bundle, 'session');
    this.flags = result.flags;
    this.session = result.session;
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }

  checkFlagHidden(flag: FlagIonic) {
    if (environment.flag.flagTypes.badgeFlags.indexOf(flag.name) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  async get(id: string = '', page: string = '', autoFlag: boolean = false) {
    const options: ViewOptions = {
      display_id: 'app_order_delegates',
      filters: {
        id,
        paid: this.filterPaid,
        checked_in: this.filterCheckedIn,
        badge_printed: this.filterBadgePrinted,
        // badge_pre_printed: this.filterBadgePrePrinted,
        field_meeting_location_target_id: this.filterLocation,
        group_manager_target_id: this.filterGroup,
        contact_first_name_value: this.filterFirstName,
        delegate_type_value: this.filterDelegateType,
        page,
        items_per_page: this.resultsCount
      }
    };
    console.log(options);
    this.entity.findRegistration('registration/badges', options, autoFlag).then(async (res: FoundRegistrations) => {
      if (res) {
        this.headers = res.headers;
        this.pager = res.pager;
        this.entity.setupBadgeType(res.results);
        this.entity.setupBadgeControls(res.results);
        this.data = res.results;
        if (this.loading) {
          await this.loading.dismiss();
          this.loading = null;
        }
        this.noResultsData = res.noResultsData;
      } else {
        this.data = null;
        this.noResultsData = true;
      }
    });
  }

  async getAdmin(id: string = '', page: string = '', autoFlag: boolean = false) {
    const options: ViewOptions = {
      display_id: 'app_order_delegates',
      filters: {
        id,
        paid: this.filterPaid,
        checked_in: this.filterCheckedIn,
        badge_printed: this.filterBadgePrinted,
        // badge_pre_printed: this.filterBadgePrePrinted,
        field_meeting_location_target_id: this.filterLocation,
        group_manager_target_id: this.filterGroup,
        contact_first_name_value: this.filterFirstName,
        delegate_type_value: this.filterDelegateType,
        page,
        items_per_page: this.resultsCount
      }
    };
    this.entity.findRegistration('registration/badges-admin', options, autoFlag).then(async (res: FoundRegistrations) => {
      if (res) {
        this.headers = res.headers;
        this.pager = res.pager;
        this.entity.setupBadgeType(res.results);
        this.entity.setupBadgeControls(res.results);
        this.data = res.results;
        if (this.loading) {
          await this.loading.dismiss();
          this.loading = null;
        }
        this.noResultsData = res.noResultsData;
      } else {
        this.data = null;
        this.noResultsData = true;
      }
    });
  }


  changeBadgeValue(entity: any, key: string) {
    if (entity[key] === '' && entity[key] !== entity[key+'_original']) {
      entity[key] = entity[key+'_original'];
    }
  }

  getDelegateType(entity: any): string {
    return this.entity.getDelegateType(entity);
  }

  getGroupLabel(entity: any): string {
    return this.entity.getGroupLabel(entity);
  }

  async search(input: any, showLoading: boolean = true, autoflag: boolean = false) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Searching for ' + input,
          backdropDismiss: false,
        });
        await this.loading.present();
      }
      this.get(input, '', autoflag);
    } else {
      if (showLoading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Loading...',
          backdropDismiss: false,
        });
        await this.loading.present();
      }
      this.get();
    }
  }

  async searchAdmin(input: any, showLoading: boolean = true, autoflag: boolean = false) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Searching for ' + input,
          backdropDismiss: false,
        });
        await this.loading.present();
      }
      this.getAdmin(input, '', autoflag);
    } else {
      if (showLoading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Loading...',
          backdropDismiss: false,
        });
        await this.loading.present();
      }
      this.getAdmin();
    }
  }

  async printCardPage() {
    if (this.data) {
      const popupWinWidth = 1000;
      const popupWinHeight = 1000;
      const pageTitle = 'PRINT';
      const left = (screen.width - popupWinWidth) / 2;
      const top = (screen.height - popupWinHeight) / 4;
      const printBadges = window.open('', pageTitle,
          'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left);

      printBadges.document.write('<html><head><title>' + document.title  + '</title>');
      printBadges.document.write(`<style>
      .card-badge {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
    .card-badge ion-col .badge {
      flex: none;
      font-family: "avenir next", sans-serif;
      margin: 0rem .5rem 2rem;
      position: relative;
      height: 2.11in;
      width: 3.39in;
      background-repeat: no-repeat;
      background-size: 100% !important;
      padding: 0;
      border: .1rem solid #cacaca;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      color: black;
      }
    .card-badge ion-col .badge-name {
      position: absolute;
      font-size: 20pt;
      line-height: 18pt;
      font-weight: 700;
      min-height: .55in;
      text-transform: uppercase;
      text-align: left;
      color: white;
      margin-top: 0.8in;
      margin-left: 0.15in;
      margin-bottom: 0;
    }
    .card-badge ion-col .badge-name .first-name, .card-badge ion-col .badge-name .last-name {
        display: block;
    }
    .card-badge ion-col .delegate-type,
    .card-badge ion-col .seat-number {
      font-size: 10.5pt;
      font-weight: 700;
      align-items: center;
      display: flex;
      position: absolute;
      color: white;
      margin-top: 0;
      margin-left: .15in;
      top: 1.32in;
  }
    .card-badge ion-col .seat-number .seat-icon {
        max-width: 18pt !important;
        margin-right: 3pt;
    }
    .card-badge ion-col .country-name,
    .card-badge ion-col .church-name {
      font-size: 10pt;
      text-transform: uppercase;
      position: absolute;
      text-align: left;
      bottom: 0.1in;
      margin-left: .15in;
  }
    .card-badge ion-col .entity-id {
      display: flex;
      transform: rotateZ(-90deg);
      transform-origin: bottom right;
      font-size: 6.5pt;
      font-weight: 700;
      position: absolute;
      justify-content: center;
      right: .05in;
      bottom: 1.5in;
      color: white;
      background: #343434;
      padding: 2px;
  }
    .card-badge ion-col ngx-qrcode {
      max-width: 80px !important;
      position: absolute;
      bottom: .1in;
      right: .25in;
      transform: rotateZ(0deg);
      display: block;
      margin: 0 auto;
  }
  .card-badge ion-col ngx-qrcode img {
    max-width: 80px !important;
  }
    .card-badge ion-col .order-id {
      display: flex;
      flex-flow: column;
      text-align: right;
      font-size: 9pt;
      font-weight: 700;
      position: absolute;
      top: .15in;
      margin: 0 .2in;
      justify-content: space-between;
      color: white;
      right: 0;
  }
  .card-badge ion-col.roundtable .badge-name,
  .card-badge ion-col.roundtable .delegate-type,
  .card-badge ion-col.roundtable .order-id {
    color: #343434;
  }
  .card-badge ion-col.roundtable .country-name {
    color: white;
  }
    .hidden-print, 
    .ion-hide-print {display:none !important;}

    @media print {
        .card-badge {
            display: block;
            flex-flow: column;
            margin: 0;
      }
        .card-badge ion-col {
            float: none;
            page-break-after: always;
            border: none;
            padding: 0rem;
            margin: 0 auto;
            display: block;
      }
      .card-badge ion-col .badge {
        float: none;
        border: none;
        padding: 0rem;
        margin: 0 auto;
        display: block;
      }
        .card-badge ion-col:last-child {
            page-break-after: auto;
      }
      .hidden-print, 
      .ion-hide-print {display:none !important;}
    }</style>`);
      printBadges.document.write('</head><body class="card-badge">');
      printBadges.document.write(document.getElementById('printCardArea').innerHTML);
      printBadges.document.write('</body></html>');

      printBadges.document.close(); // necessary for IE >= 10
      printBadges.focus(); // necessary for IE >= 10*/

      await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
      // printBadges.print();
      // printBadges.close();
  }
}

  async printPage() {

    if (this.data) {
      const popupWinWidth = 1000;
      const popupWinHeight = 1000;
      const pageTitle = 'PRINT';
      const left = (screen.width - popupWinWidth) / 2;
      const top = (screen.height - popupWinHeight) / 4;
      const printBadges = window.open('', pageTitle,
          'resizable=yes, width=' + popupWinWidth + ', height=' + popupWinHeight + ', top=' + top + ', left=' + left);

      printBadges.document.write('<html><head><title>' + document.title  + '</title>');
      printBadges.document.write(`<style>
      .slc-badge {text-align: center;}
    .slc-badge ion-col {
        flex: none;
        font-family: "avenir next", sans-serif;
        margin: 0rem 0.5rem 2rem;
        position: relative;
        width: 2.75in;
        height: 7.85in;
        background-image: url('${environment.badgeTemplate}') !important;
        background-repeat: no-repeat;
        background-size: 100% !important;
        padding: 0;
        border: 0.1rem solid #cacaca;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        color: black;
    }
    .slc-badge ion-col .badge-name {
        position: relative;
        top: 1.8in;
        font-size: 22pt;
        line-height: 22pt;
        font-weight: 700;
        margin: 0 auto;
        min-height: 0.85in;
        text-transform: uppercase;
        color: black;
    }
    .slc-badge ion-col .badge-name .first-name, .slc-badge ion-col .badge-name .last-name {
        display: block;
    }
    .slc-badge ion-col .delegate-type,
    .slc-badge ion-col .seat-number {
        font-size: 1rem;
        font-weight: 700;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        top: 2.1in;
        margin-bottom: 10px;
    }
    .slc-badge ion-col .seat-number .seat-icon {
        max-width: 18pt !important;
        margin-right: 3pt;
    }
    .slc-badge ion-col .country-name,
    .slc-badge ion-col .church-name {
        font-size: 10pt;
        text-transform: uppercase;
        position: relative;
        top: 2.05in;
    }
    .slc-badge ion-col .entity-id {
        display: flex;
        transform: rotateZ(180deg);
        font-size: 10pt;
        font-weight: 700;
        position: relative;
        top: 3.75in;
        justify-content: center;
    }
    .slc-badge ion-col ngx-qrcode {
        max-width: 120px !important;
        position: relative;
        top: 3.77in;
        transform: rotateZ(180deg);
        display: block;
        margin: 0 auto;
    }
    .slc-badge ion-col .order-id {
      display: flex;
      font-size: 9pt;
      font-weight: 700;
      position: relative;
      top: 4.25in;
      margin: 0 .2in;
      justify-content: space-between;
    }
    .slc-badge ion-col .order-id ion-text:first-child {
      transform: rotate(180deg);
    }
    .hidden-print, 
    .ion-hide-print {display:none !important;}

    @media print {
        .slc-badge {
            display: block;
            flex-flow: column;
            margin: 0;
      }
        .slc-badge ion-col {
            float: none;
            page-break-after: always;
            border: none;
            padding: 0rem;
            margin: 0 auto;
            display: block;
      }
        .slc-badge ion-col:last-child {
            page-break-after: auto;
      }
      .hidden-print, 
      .ion-hide-print {display:none !important;}
    }</style>`);
      printBadges.document.write('</head><body class="slc-badge">');
      printBadges.document.write(document.getElementById('printArea').innerHTML);
      printBadges.document.write('</body></html>');

      printBadges.document.close(); // necessary for IE >= 10
      printBadges.focus(); // necessary for IE >= 10*/

      await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
      printBadges.print();
      printBadges.close();
  }
}

  resetSearch() {
    this.inputData = '';
    this.scannedInput = '';
    this.data = null;
    this.message.showLoading('Resetting', false, 300);
  }

}
