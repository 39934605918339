/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { MainService } from './drupal7/drupal7-services.module';
import { PostalAddress, SystemConnection, ElavonCreditCardTransaction,
  ElavonProcess, ElavonSubmitInfo, APICommerceOrder, CreditCard, Payment, CommercePaymentTransaction } from './drupal7/models';

@Injectable({
  providedIn: 'root'
})
export class ElavonService extends MainService {
  entityType = 'commerce_elavon';
  instance_id = 'elavon_direct|commerce_payment_elavon_direct';

  // prepare(billingData: PostalAddress, cardDetails: CreditCard, payment: Payment, session: SystemConnection) {

  //   let extData = '';
  //   // Build and populate the API request SimpleXML element.
  //   extData += '<CustomerID>' + session.user.uid + '</CustomerID>';
  //       // Customer Billing Address.
  //   extData += '<Invoice><BillTo>';
  //   const nameOnCard = billingData.first_name + ' ' + billingData.last_name;
  //   // Use company name as billing name when available.
  //   if (billingData.organisation_name) {
  //     extData += '<Name>' + billingData.organisation_name + '</Name>';
  //   } else {
  //     extData += '<Name>' + nameOnCard + '</Name>';
  //   }
  //   extData += '<Email>' + session.user.mail + '</Email>';
  //   extData += '<Address>';
  //   extData += '<Street>' + billingData.thoroughfare + '</Street>';
  //   extData += '<City>' + billingData.locality + '</City>';
  //   extData += '<State>' + billingData.administrative_area + '</State>';
  //   extData += '<Zip>' + billingData.postal_code + '</Zip>';
  //   extData += '<Country>' + billingData.country + '</Country>';
  //   extData += '</Address></BillTo></Invoice>';

  //   // Format the expiration date
  //   const expDate = cardDetails.exp_month.substring(5, 7) + cardDetails.exp_year.substring(2, 4);

  //   const info = {
  //     ssl_card_number: cardDetails.card_number,
  //     ssl_exp_date: expDate,
  //     CVNum: cardDetails.code,
  //     ssl_amount: payment.amount / 100,

  //     ssl_avs_address: billingData.thoroughfare,
  //     ssl_avs_zip: billingData.postal_code,
  //     ssl_first_name: billingData.first_name,
  //     ssl_last_name: billingData.last_name,
  //     ssl_transaction_type: 'ccsale',
  //     ssl_cvv2cvc2: cardDetails.code,
  //     ssl_salestax: 0,

  //     InvNum: payment.order_id,
  //     ssl_show_form: 'false',

  //     NameOnCard: nameOnCard,
  //     Street: billingData.thoroughfare,
  //     Zip: billingData.postal_code,
  //     ExtData: extData
  //   };

  //   // Format the amount
  //   const formattedAmount = parseInt(payment.amount.toString().replace('.', ''), 10);
  //   const charge = {amount: formattedAmount, currency_code: payment.currency_code};

  //   const body = {
  //     instance_id: this.instance_id,
  //     info,
  //     order_id: payment.order_id,
  //     charge,
  //     uid: session.user.uid
  //   };
  //   return body;
  // }

  /**
   * implement create resource
   *
   * @param elavonTransaction payment transaction entity to create
   * @return created payment transaction details
   */
    createPaymentTransaction(elavonTransaction: ElavonProcess): Promise<CommercePaymentTransaction> {
      return this.post(elavonTransaction);
    }


  elavonPaymentTransactionProcessPrepare(billingData: PostalAddress, cardDetails: ElavonCreditCardTransaction, payment: Payment,
    order: APICommerceOrder, session: SystemConnection): ElavonProcess {
    const formattedAmount = parseInt(payment.amount.toString().replace('.', ''), 10);
    const charge = {amount: formattedAmount, currency_code: payment.currency_code};
    const expDate = cardDetails.exp_month.substring(5, 7) + cardDetails.exp_year.substring(2, 4);

    const info: ElavonSubmitInfo = {
      ssl_card_number: +cardDetails.card_number,
      ssl_exp_date: expDate,
      ssl_cvv2cvc2: cardDetails.code,
      ssl_cvv2cvc2_indicator: 1,

      ssl_amount: payment.amount / 100,
      ssl_avs_address: billingData.thoroughfare,
      ssl_address2: billingData.premise,
      ssl_city: billingData.locality,
      ssl_state: billingData.administrative_area,
      ssl_avs_zip: billingData.postal_code,
      ssl_first_name: billingData.first_name,
      ssl_last_name: billingData.last_name,
      ssl_salestax: 0,
      ssl_invoice_number: +order.order_number,

      ssl_transaction_type: 'ccsale',
    };

    const body: ElavonProcess = {
      instance_id: this.instance_id,
      info,
      order,
      charge,
      uid: session.user.uid,
      card_details: cardDetails
    };

    console.log(body);
    return body;
  }

  elavonPaymentTransactionProcess(billingData: PostalAddress, cardDetails: ElavonCreditCardTransaction, payment: Payment,
    order: APICommerceOrder, session: SystemConnection): Promise<any> {
    const body = this.elavonPaymentTransactionProcessPrepare(billingData, cardDetails, payment, order, session);
    return this.post(body);
  }
}

