import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./check-in/check-in.module').then( m => m.CheckInPageModule)
  },
  {
    path: 'check-in',
    loadChildren: () => import('./check-in/check-in.module').then( m => m.CheckInPageModule)
  },
  {
    path: 'badges',
    loadChildren: () => import('./badges/badges.module').then(m => m.BadgesComponentModule)
  },
  {
    path: ':id/:id/create',
    loadChildren: () => import('./form-inline/form-inline.module').then(m => m.FormInlinePageModule)
  },
  {
    path: ':id/:id/:id/create',
    loadChildren: () => import('./form-inline/form-inline.module').then(m => m.FormInlinePageModule)
  },
  {
    path: ':id/:id/:id/edit',
    loadChildren: () => import('./form-inline/form-inline.module').then(m => m.FormInlinePageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'pay/:id',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderComponentModule)
  },
  {
    path: 'manage/manage-churches',
    loadChildren: () => import('./manage/manage-churches/manage-churches.module').then( m => m.ManageChurchesComponentModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./badges-new/badges.module').then(m => m.BadgesNewComponentModule)
  },
  {
    path: 'manage/manage-registrations',
    loadChildren: () => import('./manage/manage-registrations/manage-registrations.module').then( m => m.ManageRegistrationsComponentModule)
  },];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
