/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../services/user.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { MessagesService } from '../services/messages.service';
import { IonInput, IonSearchbar, LoadingController, ModalController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { KeyValue } from '@angular/common';
import { EntityServiceCustom } from '../services/entity.service';
import { FlagServiceCustom } from '../services/flag.service';
import { PaymentPage } from '../payment/payment.page';
import { OrderComponent } from '../order/order.component';
import { CodeScanService } from '../services/codescan.service';
import { FormsService } from '../services/forms.service';
import { FlagIonic, ViewOptions, DrupalFormControlObject, CommerceOrderStatus, FormData, FormFromJSON, FoundRegistrations, Pager } from '../services/drupal7/models';
import { CommerceCartService, FlagService, MainService, ViewService } from '../services/drupal7/drupal7-services.module';
import { QRCode } from '../services/drupal7/models/qrcode';
import jsQR from 'jsqr';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.page.html',
  styleUrls: ['./check-in.page.scss'],
})
export class CheckInPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  @Input() session: any;
  @ViewChild('scannedInputField', {static: false}) scannedInputField: IonInput;
  @ViewChild('video', {static: false}) video: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;

  scanActive = false;
  localStream: any;

  videoElement: any;
  canvasElement: any;
  canvasContext: any;

  loading: HTMLIonLoadingElement;


  flags: Array<FlagIonic>;
  checkInOutFlags: Array<FlagIonic>;
  headers: Array<string>;
  data: Array<FormData>;
  noResultsData = true;
  inputData: string | number;
  inputOrderID: string | number;
  scannedInput: string | number;
  paidCount = 0;
  pager: Pager;
  sortDirection = 0;
  sortKey = null;
  bulkEdit = false;
  editObj = {};
  resultsCount = 10;
  itemsPerPage = [5, 10, 25, 50];
  numberFlagged: number;
  currentMode = 'check-in';
  cardLayout = true;
  manualEntry = false;
  leftPage = false;
  orderStatus = CommerceOrderStatus;
  formSchema: FormFromJSON;

  constructor(
    private flag: FlagServiceCustom,
    private flagService: FlagService,
    private mainService: MainService,
    private entity: EntityServiceCustom,
    private codeScan: CodeScanService,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    public router: Router,
    private forms: FormsService,
    public storage: Storage,
    private barcodeScanner: BarcodeScanner,
    private modalController: ModalController,
    private viewService: ViewService,
    private commerceCartService: CommerceCartService,
    private user: UserServiceCustom,
    public message: MessagesService
    ) {

      console.log('listening for check in scan');
      this.leftPage = false;
      this.user.currentSession.subscribe(session => this.session = session);
      if (!this.session) {
        this.user.changeSession(null);
      } else {
        document.addEventListener('keypress', (evt: KeyboardEvent) => {
            this.physicalScan(evt);
        }, true);
        this.initializeData();
      }
   }

   @HostListener('window:beforeunload')
   ngOnDestroy() {
     console.log('check in component destroyed');
     this.leftPage = true;
     document.removeEventListener('keypress', (evt: KeyboardEvent) => {
   }, true);
 }

 ngOnInit() {
   this.user.currentSession.subscribe(session => this.session = session);
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === 'check-in') {
        this.outlet.deactivate();
      }
    });
  }

 ngAfterViewInit() {
  this.videoElement = this.video?.nativeElement;
  this.canvasElement = this.canvas?.nativeElement;
  this.canvasContext = this.canvasElement?.getContext('2d');
 }

 async startScan() {
  this.localStream = await navigator.mediaDevices.getUserMedia({
    video: {facingMode: 'environment'}
  });
  this.videoElement.srcObject = this.localStream;
  this.videoElement.setAttribute('playsinline', true);
  this.videoElement.play();
  this.loading = await this.loadingCtrl.create({});
  await this.loading.present();
  requestAnimationFrame(this.scan.bind(this));
 }

 async scan() {
  if (this.videoElement.readyState === this.videoElement.HAVE_ENOUGH_DATA) {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
      this.scanActive = true;
    }

    this.canvasElement.height = this.videoElement.videoHeight;
    this.canvasElement.width = this.videoElement.videoWidth;
    this.canvasContext.drawImage(
      this.videoElement,
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    const imageData = this.canvasContext.getImageData(
      0,
      0,
      this.canvasElement.width,
      this.canvasElement.height
    );
    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert'
    });
    if (code) {
      this.scanActive = false;
      this.message.showLoading('Searching...', false, 1500);
      const res = await this.codeScan.parseScan(code.data);
      if (res.id && res.order_id) {
        this.find(res);
      } else {
        this.message.presentAlert('Invalid Scan', 'The scanned code is invalid.');
      }
      this.stopScan();
    } else {
      if (this.scanActive) {
        requestAnimationFrame(this.scan.bind(this));
      }
    }
  } else {
    requestAnimationFrame(this.scan.bind(this));
  }
 }

 async stopScan() {
  this.scanActive = false;
  this.localStream.getTracks().forEach(track => track.stop());
}

async find(data: QRCode) {
  const options: ViewOptions = {
    display_id: 'app_order_delegates',
    args: [data.id]
  };
  this.entity.findRegistration('registration', options).then((res: FoundRegistrations) => {
    this.headers = res.headers;
    this.paidCount = res.paidCount;
    this.pager = res.pager;
    this.data = res.results;
    this.noResultsData = res.noResultsData;
  });
}

   async physicalScan(event) {
    if (!this.manualEntry && !this.leftPage) {
      const scannedData = await this.codeScan.scan(event);
      console.log(scannedData);

      if (scannedData) {
        if (scannedData.id) {
          if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
          }
          this.find(scannedData);
        }
        // if (scannedData.order_id) {
        //   console.log('this is the order id');
        //   this.scannedInputField.value = scannedData.order_id;
        //   this.message.showLoading('Checking the status of order ' + scannedData.order_id, false, 800);
        //   const options: ViewOptions = {
        //     display_id: 'api_registration_orders',
        //     args: ['all', scannedData.order_id],
        //   };
        //   const order = await this.viewService.getView('registration', options).then((foundOrder: any) => foundOrder[0]);
        //   console.log(order);
        //   if (order.state === this.orderStatus.invoiced) {
        //     this.loadOrder(scannedData.order_id);
        //   } else if (order.state !== this.orderStatus.canceled) {
        //     this.pay(scannedData);
        //   }
        // } else if (scannedData.id) {
        //   console.log('this is the delegate id');
        //   this.search(scannedData.id, true, true);
        // }
        // if (scannedData.uid) {
        //   console.log('this is the user id');
        //   console.log(scannedData.uid);
        // }
      }
    }
  }

  searchFromScan(val: any) {
    console.log(val);
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => a.value.localeCompare(b.value);

  async initializeData() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
    this.checkInOutFlags = await this.storage.get('flags_by_type').then(res => res?.checkInOutFlags);
    const checkSelf = await this.storage.get('selfCheckIn');
    if (!checkSelf) {
      if (this.session) {
        this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
        this.checkInOutFlags = await this.storage.get('flags_by_type').then(res => res?.checkInOutFlags);
        this.formSchema = await this.forms.getForm(this.session, environment.checkInType.entityType, environment.checkInType.bundle);
        if (!this.flags) {
          this.setFlagAccess(false);
          this.message.showLoading('Setting up your data', false, 2000);
        }
      } else {
        await this.user.loginForm('session', environment.checkInType.entityType, environment.checkInType.bundle);
        this.session = await this.storage.get('session');
        if (this.session) {
          this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
          this.checkInOutFlags = await this.storage.get('flags_by_type').then(res => res?.checkInOutFlags);
          if (!this.formSchema) {
            this.formSchema = await this.forms.getForm(this.session, environment.checkInType.entityType, environment.checkInType.bundle);
          }
          if (!this.flags) {
            this.setFlagAccess(false);
            this.message.showLoading('Setting up your data', false, 2000);
          }
        }
      }
    }
  }

  sessionChange(e) {
    this.session = e;
    this.initializeData();
  }
  async flagChange() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
  }

  async segmentChanged(val: any) {
    this.currentMode = val;
    console.log(this.currentMode);
    // this.message.showLoading('Loading page...', false, 2000);
    // this.get();
  }

  scanQRCode() {
    if (this.platform.is('capacitor')) {
      this.barcodeScanner.scan({showFlipCameraButton: true, preferFrontCamera: true}).then(barcodeData => {
        if (barcodeData.text && !barcodeData.cancelled) {
          this.message.showLoading('Searching for ' + barcodeData.text, false, 1500);
          this.get(barcodeData.text, '', true);
        }
       }).catch(err => {
           console.log('Error', err);
       });
    } else {
      this.startScan();
      // this.scannedInput = '';
      // this.searchInput.setFocus();
    }
  }

  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    if (flag.name === 'checked_in') {
      this.updateEmail(item, 'contact_email', flag, flagValue);
    } else {
      this.flag.setupFlagMessageAndFlag(item, flag, flagValue);
    }
  }

  async setFlagAccess(showLoading: boolean = true) {
    const session = await this.storage.get('session');
    if (showLoading) {
      this.message.presentToast('Refreshing your account access.', 2000);
    }
    const result = await this.user.setFlagAccess(session, environment.checkInType.entityType, environment.checkInType.bundle, 'session');
    this.flags = result.flags;
    this.session = result.session;
  }

  loadOrder(orderID: string) {
    this.inputData = '';
    this.data = null;
    this.message.showLoading('Loading delegates from order ' + orderID, false, 1500);
    this.inputOrderID = orderID;
    this.resultsCount = 60;
    this.get();
    this.resultsCount = 20;
  }

  pagerChange(id: string = '', page: string = '') {
    this.message.showLoading('Loading page ' + page, false, 1500);
    this.get(id, page);
  }

  bulkFlag() {
    const flag: FlagIonic = {
      fid: 1,
      entity_type: 'node',
      global: 1,
      name: 'checked_in',
      label: 'Checked In',
      title: 'Checked In'
    };
    this.flag.bulkFlag(this.data, flag, 'flag', this.session).then(res => {
      console.log(res);
    });
  }

  async getCurrentOrder() {
    this.commerceCartService.getAllOrders().then(res => {
      console.log(res);
    });
  }

  getDelegateType(entity: any): string {
    return this.entity.getDelegateType(entity);
  }

  getGroupLabel(entity: any): string {
    return this.entity.getGroupLabel(entity);
  }

  async get(id: string = '', page: string = '', autoFlag: boolean = false) {
    const options: ViewOptions = {
      display_id: 'app_order_delegates',
      filters: {
        id,
        page,
        items_per_page: this.resultsCount
      }
    };
    console.log(this.formSchema);
    if (!this.formSchema?.formGroup) {
      this.formSchema = await this.forms.getForm(this.session, environment.checkInType.entityType, environment.checkInType.bundle);
    }
    this.entity.findRegistration('registration', options, autoFlag).then(async (res: FoundRegistrations) => {
      if (res.noResultsData) {
        this.message.presentAlert('An Error Occurred', 'Please try again');
      }
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
      }

      this.headers = res.headers;
      this.paidCount = res.paidCount;
      this.pager = res.pager;
      this.data = res.results;
      this.noResultsData = res.noResultsData;
    }, err => {
      this.message.presentAlert('An Error Occurred', err);
    });
  }

  async create(entity: any) {
    this.router.navigate([`${entity.entity_type}/${entity.bundle}/${entity.uid}/create`]);
  }

  async edit(entity) {
    this.router.navigate([`${entity.entity_type}/${entity.bundle}/${entity.nid}/edit`], {state: {
        loadedEntity: entity,
        entityType: entity.entity_type,
        entityBundle: entity.bundle,
        session: this.session,
    }});
    // const modal = await this.modalController.create({
    //   component: FormComponent,
    //   cssClass: 'edit-entity fullscreen',
    //   componentProps: {
    //     loadedEntity: entity,
    //     entityType: entity.entity_type,
    //     entityBundle: entity.bundle,
    //     session: this.session,
    //     requestOptions: this.requestOptions
    //   }
    // });
    // await modal.present();
    // const { data } = await modal.onDidDismiss();
    // console.log(data);
  }

  async updateEmail(entity: any, key: string, flag: FlagIonic, flagValue: boolean) {
    // this.message.showLoading('Loading...', false, 1500);
    const form = await this.forms.getForm(this.session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      // for (const g of form.formFields) {
        const formField: DrupalFormControlObject = form.form_fields.filter(o => o.field_name === key)[0];
        // if (g.fields) {
        //   formField = g.fields.filter(o => o.field_name === key)[0];
        // }
        if (formField !== undefined) {
          const inputs = [];
          const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
          if (formField.options) {
            for (const option of formField.options) {
              inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
            }
          } else if (formField.ionic_type === 'boolean') {
            inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
          } else {
            inputs.push(
              {
                name: key,
                type: formField.type,
                id: key,
                placeholder: '',
                value: entity[key]
              }
            );
          }
          this.entity.presentUpdateEmailAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
            if (val) {
              this.flag.setupFlagMessageAndFlag(entity, flag, flagValue);
              return entity[key] = val;
            }
          });
        }
      // }
      return true;
    } else {
      return false;
    }
  }


  async updateField(entity: any, key: string) {
    // this.message.showLoading('Loading...', false, 1500);
    const form = await this.forms.getForm(this.session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      // for (const g of form.formFields) {
        const formField: DrupalFormControlObject = form.form_fields.filter(o => o.field_name === key)[0];
        // if (g.fields) {
        //   formField = g.fields.filter(o => o.field_name === key)[0];
        // }
        if (formField !== undefined) {
          const inputs = [];
          const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
          if (formField.options) {
            for (const option of formField.options) {
              inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
            }
          } else if (formField.ionic_type === 'boolean') {
            inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
          } else {
            inputs.push(
              {
                name: key,
                type: formField.type,
                id: key,
                placeholder: '',
                value: entity[key]
              }
            );
          }
          this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
            if (val) {
              this.flag.unflagBadgePrinted(entity, key, val);
              return entity[key] = val;
            }
          });
        }
      // }
      return true;
    } else {
      return false;
    }
  }

  async pay(lineItem: any) {
    this.router.navigate([`pay/${lineItem.order_id}`]);

    // this.message.showLoading('Fetching the order', false, 1000);
    // const options: ViewOptions = {
    //   display_id: 'api_registration_orders',
    //   args: [lineItem.order_id],
    // };
    // const order = await this.viewService.getView('registration/commerce-order', options).then((foundOrder: any) => {
    //   console.log(foundOrder);
    //   return foundOrder[0];
    // });
    // console.log(order);
    // const modal = await this.modalController.create({
    //   component: PaymentPage,
    //   cssClass: 'fullscreen',
    //   componentProps: {
    //     order,
    //     session: this.session,
    //   }
    // });
    // this.leftPage = true;
    // await modal.present();
    // const res = await modal.onDidDismiss();
    // console.log(res);
    // this.leftPage = false;
    // if (res.role === 'submit') {
    //   this.inputData = '';
    //   this.data = null;
    //   this.message.showLoading('Loading delegates from order ' + lineItem.order_id, false, 2000);
    //   this.inputOrderID = lineItem.order_id;
    //   this.resultsCount = 60;
    //   this.loadOrderAndFlagAsCheckedIn();
    //   this.resultsCount = 20;
    // }
  }

  async viewOrder(lineItem: any) {
    this.message.showLoading('Fetching the order', false, 3000);
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [lineItem.order_id],
    };
    const order = await this.viewService.getView('registration/commerce-order', options).then((foundOrder: any) => foundOrder[0]);
    console.log(order);
    const modal = await this.modalController.create({
      component: OrderComponent,
      cssClass: 'fullscreen',
      componentProps: {
        order,
        session: this.session,
      }
    });
    this.leftPage = true;
    await modal.present();
    const res = await modal.onDidDismiss();
    this.leftPage = false;
    console.log(res);
  }

  async loadOrderAndFlagAsCheckedIn() {
    const options: ViewOptions = {
      display_id: 'app_order_delegates',
      args: ['all', this.inputOrderID],
      filters: {
        page: '',
        items_per_page: this.resultsCount
      }
    };
    const session = await this.storage.get('session');
    return this.viewService.getView('registration', options).then(async (res: any) => {
      console.log(res);
      const result = [];
      const items = [];
      if (res[0]) {
        this.headers = Object.keys(res[0]).map(key => key);
        Object.keys(res).map(key => key !== 'pager' ? items.push(res[key]) : null);
        for (const item of items) {
          result.push(item);
        }

        // Remove the duplicates from the array because they can't be removed from the api
        // because the data from the api is being sorted by order id, line item id, and then nid.
        // This is sorted this way on from the api because the badges are sorted this way. This makes
        // it so the data from the api and the physical badges are grouped and sorted the same way.

        // Need a different way of getting the page summary to display the correct number because we want to remove
        // duplicates from the final result.
        const uniqueResult = result;

        if (res.pager) {
          this.pager = res.pager;
          this.pager.links = Array.from(new Array(res.pager?.pages), (x,i) => i);
        }
      this.noResultsData = false;

        const flags: Array<FlagIonic> = await this.storage.get('flags_by_type').then(flagArray => flagArray?.checkInOutFlags);
        for (const flag of flags) {
          if (session.user.flag_access.flag[flag.name]) {
            for (const entity of uniqueResult) {
              if (!entity['flag_'+flag.name] && flag.name === 'checked_in') {
                this.flagEntity(entity, flag, entity['flag_'+flag.name]);
              }
            }
          }
        }
        this.mainService.removeProps(uniqueResult,['pager']);
        this.entity.setupQRCode(uniqueResult);
        this.entity.setupUniqueOrders(uniqueResult);
        this.entity.setupChildcare(uniqueResult);
        this.paidCount = this.entity.setupPaidCount(uniqueResult);
        console.log(uniqueResult);

        return this.data = uniqueResult;
      }
      if (res.pager?.page === -1) {
        return this.noResultsData = true;
      }
    });
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }

  checkFlagHidden(flag: FlagIonic, entity: any) {
    return this.flag.checkFlagHidden(flag, entity, this.session);
  }

  checkFlagHiddenAdmin(flag: FlagIonic, entity: any) {
    return false;
    // return this.flag.checkFlagHiddenAdmin(flag, entity, this.session);
  }

  checkForDuplicate(entity: any, i: number) {
    const index = this.data.map((delegate) => delegate.nid).indexOf(entity.nid);
    if (index !== i) {
      entity.shadowCopy = true;
      return true;
    } else {
      return false;
    }
  }

  canUpdate(entity: any, key: string) {
    if (entity.author[0].rid === 'unverified user') {
      return false;
    } else {
      return this.formSchema.formGroup.controls[key] ? true : false;
    }
  }

  async search(input: any, showLoading: boolean = true, autoflag: boolean = false) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.loading = await this.loadingCtrl.create({
          message: 'Searching for ' + input,
          backdropDismiss: false,
        });
        await this.loading.present();
      }
      this.inputOrderID = '';
      this.get(input, '', autoflag);
    } else {
      this.get();
    }
  }

  resetSearch() {
    this.inputData = '';
    this.inputOrderID = '';
    this.data = null;
    this.message.showLoading('Resetting', false, 300);
  }

  sortBy(key) {
    this.sortKey = key;
    this.sortDirection++;
    this.sort();
  }

  sort() {
    if (this.sortDirection === 1) {
      this.data = this.data.sort((a, b) => a[this.sortKey].localeCompare(b[this.sortKey]));
    } else if (this.sortDirection === 2) {
      this.data = this.data.sort((a, b) => b[this.sortKey].localeCompare(a[this.sortKey]));
    } else {
      this.sortDirection = 0;
      this.sortKey = null;
      this.data = this.data.sort((a, b) => b.id.localeCompare(a.id));
    }
  }

  toggleBulkEdit() {
    this.bulkEdit = !this.bulkEdit;
    this.editObj = {};
    for (const row of this.data) {
      row.edit = false;
    }
  }

  bulkDelete() {
    console.log(this.edit);
    const toDelete = Object.keys(this.edit);
    const reallyDelete = toDelete.filter(index => this.edit[index]).map(key => +key);
    while (reallyDelete.length) {
      this.data.splice(reallyDelete.pop(), 1);
    }
    this.toggleBulkEdit();
  }

  toggleSelectAll(checked) {
    const val = checked ? false : true;
    for (const row of this.data) {
      row.edit = val;
    }
    console.log(this.edit);
  }
}
