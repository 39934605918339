/* eslint-disable @typescript-eslint/naming-convention */
import { formatCurrency } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessagesService } from './messages.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import PaymentMethods from '../../assets/commerce/payment_methods.json';
import { AlertController } from '@ionic/angular';
import { Payment, APICommerceOrder } from './drupal7/models/commerce';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(
    public http: HttpClient,
    private alertCtrl: AlertController,
    private message: MessagesService,
  ) {
  }

  getActivePaymentMethods(prop) {
    const activePaymentMethods = [];
    for (const paymentMethod of PaymentMethods) {
      if (paymentMethod[prop]) {
        activePaymentMethods.push(paymentMethod);
      }
    }
    return activePaymentMethods;
  }

  async overrideAmountConfirm(payment, order) {
    const alert = await this.alertCtrl.create({
      header: 'Override Payment',
      message: 'Enter the password to continue.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'password',
          type: 'password',
          id: 'password',
          placeholder: 'Password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            if (form.password === environment.appSettings.linkDevicePass) {
              this.overrideAmount(payment, order);
            } else {
              this.message.presentAlert('Invalid password', 'The password you have entered is incorrect');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async overrideAmount(payment: Payment, order: APICommerceOrder) {
    const alert = await this.alertCtrl.create({
      header: 'Enter new payment amount.',
      backdropDismiss: false,
      inputs: [
        {
          name: 'amount',
          type: 'number',
          id: 'amount',
          placeholder: 'Amount',
          value: payment.amount
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'OK',
          handler: form => {
            const newAmount = parseFloat(form.amount);
            const formattedAmount = formatCurrency(newAmount / 100, 'en-US', '$');
            payment.amount = newAmount;
            order.commerce_order_total = newAmount;
            order.balance = newAmount;
            order.balance_formatted = formattedAmount;
            order.commerce_order_total_formatted = formattedAmount;
          }
        }
      ]
    });
    await alert.present();
  }
}
