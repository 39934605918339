/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { QRCode } from './drupal7/models/qrcode';
@Injectable({
  providedIn: 'root'
})
export class CodeScanService {
    swipeHandler: any;
    scanBuffer = '';

    constructor() {
    }

addListeners() {
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
        this.scan(evt);
    });
}

deleteListeners() {
    document.removeEventListener('keypress', this.swipeHandler, true);
    console.log('Done!');
}

async scan(event) {
    // Setup the patterns to look for in the scan
    const entity_full_regex = /(?:\%id=(\d+)\^lineitemid=(\d+)\^orderid=(\d+)\^uid=(\d+))\^group=(\d+)\?/;
    const entity_regex = /(?:\%id=(\d+)\^)/;

    // Add the key to the buffer
    this.scanBuffer += String.fromCharCode(event.keyCode);
    // Set timeout to make sure we have all the data from the buffer
    await new Promise<void>(resolve => setTimeout(()=>resolve(), 700));
    if (this.scanBuffer !== null && this.scanBuffer.match(entity_full_regex)) {

        // Setup the return
        const res = await this.parseScan(this.scanBuffer);
        this.scanBuffer = '';
        return res;
    } else {
        this.scanBuffer = '';
    }
  }

  async parseScan(data: string) {
    // Setup the patterns to look for in the scan
    const entity_full_regex = /(?:\%id=(\d+)\^lineitemid=(\d+)\^orderid=(\d+)\^uid=(\d+))\^group=(\d+)\?/;
    const entity_regex = /(?:\%id=(\d+)\^)/;
    const line_item_regex = /(?:\^lineitemid=(\d+)\^)/;
    const order_id_regex = /(?:\^orderid=(\d+)\^)/;
    const uid_regex = /(?:\^uid=(\d+)\^)/;
    const group_regex = /(?:\^group=(\d+)\?)/;

    if (data !== null && data.match(entity_regex)) {
        const entityMatch = entity_regex.exec(data);
        const lineItemMatch = line_item_regex.exec(data);
        const orderIDMatch = order_id_regex.exec(data);
        const uidMatch = uid_regex.exec(data);
        const groupMatch = group_regex.exec(data);

        // Setup the return
        const qrCode: QRCode = {id: +entityMatch[1]};
        if (lineItemMatch) {
            qrCode.line_item_id = +lineItemMatch[1];
        }
        if (orderIDMatch) {
            qrCode.order_id = +orderIDMatch[1];
        }
        if (uidMatch) {
            qrCode.uid = +uidMatch[1];
        }
        if (groupMatch) {
            qrCode.group_manager = +groupMatch[1];
        } else {
            qrCode.group_manager = +'0000';
        }
        qrCode.qr_code_value = data;
        return qrCode;
    } else {
        return;
    }
  }

}


