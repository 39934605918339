import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { FlagSetting, FlagSettingsComponent } from '../flag-settings/flag-settings.component';
import { SystemConnection } from '../services/drupal7/models';
import { MenuItemMenu } from '../services/drupal7/models/menu';
import { FlagServiceCustom } from '../services/flag.service';
import { AssetsService } from '../services/assets.service';
import { MessagesService } from '../services/messages.service';
import { StorageService } from '../services/storage.service';
import { UserServiceCustom } from '../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() session: SystemConnection;
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() sessionChange: EventEmitter<any> = new EventEmitter();
  @Output() flagChange: EventEmitter<any> = new EventEmitter();
  menuNodeTypes: Array<MenuItemMenu>;
  registrationMenu: Array<MenuItemMenu>;
  manageMenu: Array<MenuItemMenu>;
  flagSettingsMenu: Array<FlagSetting>;
  startupMode: boolean;
  siteName = environment.siteName;
  bundle = environment.checkInType.bundle;
  defaultFrontCamera: boolean;

  constructor(private assetsService: AssetsService,
    private user: UserServiceCustom,
    private flag: FlagServiceCustom,
    public router: Router,
    private alertCtrl: AlertController,
    public message: MessagesService,
    private storage: StorageService,
    private modalController: ModalController,
    ) {}

  async ngOnInit() {
    this.menuNodeTypes = await this.assetsService.getAssetItems('menu/main_menu');
    this.registrationMenu = await this.assetsService.getAssetItems(`menu/${environment.checkInType.bundle}`);
    this.flagSettingsMenu = await this.assetsService.getAssetItems('menu/flag_settings');
    this.manageMenu = await this.assetsService.getAssetItems('menu/management');
    this.defaultFrontCamera = await this.storage.get('defaultFrontCamera');
    if (this.defaultFrontCamera === null) {
      this.defaultFrontCamera = false;
    }

    this.checkStartupMode();
  }

  async appLogin() {
    this.session = await this.user.loginForm('session', environment.checkInType.entityType, environment.checkInType.bundle);
    this.sessionChange.emit(this.session);
  }

  async appLogout() {
    await this.user.logout().then(res => {
      console.log(res);
      this.session = null;
      this.sessionChange.emit(this.session);
    });
  }

  async defaultCamera() {
    const defaultFrontCamera = await this.storage.get('defaultFrontCamera');
    if (defaultFrontCamera) {
      this.storage.set('defaultFrontCamera', !defaultFrontCamera);
      this.defaultFrontCamera = !defaultFrontCamera;
    } else {
      this.storage.set('defaultFrontCamera', !defaultFrontCamera);
      this.defaultFrontCamera = !defaultFrontCamera;
    }
  }


  async refreshFlagAccess() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Refresh.',
      backdropDismiss: false,
      message: 'Refreshing your access will remove your current flag and scan settings.',
      buttons: [
        {
          text: 'REFRESH',
          cssClass: 'primary',
          role: 'submit'
        }, {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (form: any) => {
      if (form.role === 'submit') {
        this.message.showLoading('Refreshing your flag access...', false, 2000);
        return await this.user.setFlagAccess(this.session, environment.checkInType.entityType, environment.checkInType.bundle, 'session');
      }
    }, err => throwError(err));
  }


  async flagSettings(storageKey: string, objKey: string, pageTitle: string) {
    const flags = await this.storage.get(storageKey).then(res => {
      if (res[objKey]) {
        return res[objKey];
      } else {
        return res;
      }
    });
    const pageDescription = this.flag.getPageTitleandDesc(objKey);
    const modal = await this.modalController.create({
      component: FlagSettingsComponent,
      cssClass: 'flag-settings',
      componentProps: {
        session: this.session,
        storageKey,
        flags,
        objKey,
        pageTitle,
        pageDescription
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.message.presentToast('Flag settings saved.', 2000);
      this.flagChange.emit();
    }
  }


  async checkStartupMode() {
    const checkSelf = await this.storage.get('selfCheckIn');
    this.startupMode = checkSelf;
    if (checkSelf) {
      this.router.navigateByUrl('self-check-in');
    }
  }

  toggleStartup() {
    this.startupMode = !this.startupMode;
    this.storage.set('selfCheckIn', this.startupMode);
    if (this.startupMode) {
      this.router.navigateByUrl('self-check-in');
    }
  }

  routeToPage(val: string) {
    this.router.navigate([val], { replaceUrl: true });
    this.pageChange.emit(val);
  }

}
