import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AssetsService } from '../services/assets.service';
import { environment } from '../../environments/environment.prod';
import { UserServiceCustom } from '../services/user.service';
import { EntityService, UserService } from '../services/drupal7/drupal7-services.module';
import { MenuItemMenu } from '../services/drupal7/models/menu';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() session: any;
  @Output() sessionChange: EventEmitter<any> = new EventEmitter();
  menuNodeTypes: Array<MenuItemMenu>;
  siteName = environment.siteName;
  constructor(
    public router: Router,
    public storage: StorageService,
    private userService: UserService,
    private entityService: EntityService,
    private user: UserServiceCustom,
    private assetsService: AssetsService,
  ) {
    this.initializeData();
  }
  ngOnInit() {
  }

  async initializeData() {
    this.menuNodeTypes = await this.assetsService.getAssetItems('menu/main_menu');
    this.session = await this.storage.get('session');
  }

  async appLogin() {
    this.session = await this.user.loginForm('session', environment.checkInType.entityType, environment.checkInType.bundle);
    this.sessionChange.emit(this.session);
  }

  async appLogout() {
    await this.user.logout().then(res => {
      console.log(res);
      this.session = null;
      this.sessionChange.emit(this.session);
    });
  }

  login(username: string, password: string) {
    const user = {
      username,
      password
    };
    this.entityService.indexEntity('user').then(res => {
      console.log(res);
    });

    this.userService.login(user).then(connection => {
      console.log(connection);
      // re-direct to user pofile or show welcome message.
      this.entityService.indexEntity('user').then(res => {
        console.log(res);
      });

    });
  }

}

