/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { Storage } from '@ionic/storage-angular';
import { MessagesService } from './messages.service';
import { StorageService } from './storage.service';
import { AlertController } from '@ionic/angular';
import { FlagAction, FlagIonic } from './drupal7/models/flag';
import { CommerceOrderStatus } from './drupal7/models/commerce';
import { FlagService } from './drupal7/drupal7-services.module';

@Injectable({
  providedIn: 'root'
})
export class FlagServiceCustom {

  constructor(
    private alertCtrl: AlertController,
    private flagService: FlagService,
    private message: MessagesService,
    private storageService: StorageService,
    public storage: Storage) { }


async autoflag(entities: any) {
  const session = await this.storage.get('session');
  const flags: Array<FlagIonic> = await this.storage.get('flags_'+environment.checkInType.bundle);
    for (const flag of flags) {
      if (session.user.flag_access.flag[flag.name] && flag.flag_on_scan && flag.status) {
        for (const entity of entities) {
          if (!entity['flag_'+flag.name]) {
            const flagMessage = 'Flagged ' + flag.label + ' for ' + entity.contact_first_name + ' ' + entity.contact_last_name;
            const unflagMessage = 'Unflagged ' + flag.label + ' for ' + entity.contact_first_name + ' ' + entity.contact_last_name;
            const newFlagStatus = await this.flagEntity(entity, flag, entity['flag_'+flag.name], session, flagMessage, unflagMessage);
            entity['flag_'+flag.name] = newFlagStatus;
            // await new Promise<void>(resolve => setTimeout(()=>resolve(), 250));
          }
        }
      }
    }
}

async bulkFlag(entities: any, flag: FlagIonic, flagAction: 'flag' | 'unflag', session: any, skipPermissionCheck?: boolean) {
  const alert = await this.alertCtrl.create({
    header: 'Confirm ' + flag.label,
    message: 'Are you sure you want to ' + flagAction + ' ' + flag.label + ' for ' + entities.length + ' entities?',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: 'Confirm',
        role: 'submit',
        cssClass: 'warning',
      }
    ]
  });
  await alert.present();
  return await alert.onDidDismiss().then(async (res: any) => {
    if (res.role === 'submit') {
      const body: FlagAction[] = [];
      for (const entity of entities) {
        const flagging: FlagAction = {
          flag_name: flag.name.replace('flag_', ''),
          entity_id: entity.nid
        };
        if (entity['flag_'+flag.name] !== undefined) {
            flagging.action = flagAction;
        }
        if (session?.user?.uid !== undefined) {
            flagging.uid = session.user.uid;
        }
        if (skipPermissionCheck !== undefined) {
            flagging.skip_permission_check = skipPermissionCheck;
        }
        body.push(flagging);
      }
      console.log(body);
      return this.flagService.bulkFlag(body).then(flaggedEntities => {
        console.log(flaggedEntities);
        const message = flagAction + 'ged ' + flag.label + ' for ' + entities.length + ' entities';
        this.message.presentToast(message, 2000);
        return flaggedEntities;
      });
    } else {
      return false;
    }
  });
}


async flagEntity(entity: any, flag: FlagIonic, flagAction: boolean, session: any,
    flagToastMessage: string, unflagToastMessage: string, storageKey?: string, confirmed: boolean = true, skipPermissionCheck?: boolean): Promise<boolean> {

      console.log(flagAction);

      if (environment.flag.flagTypes.confirmFlags.indexOf(flag.name) > -1) {
        confirmed = await this.confirmFlag(entity, flag, flagAction);
      }
      if (confirmed) {
        console.log(flagAction);
        const action = flagAction === true ? 'unflag' : 'flag';
        console.log(action);

        const flagging: FlagAction = {
          flag_name: flag.name.replace('flag_', ''),
          entity_id: entity.nid
        };
        if (action !== undefined) {
            flagging.action = action;
        }
        if (session?.user?.uid !== undefined) {
            flagging.uid = session.user.uid;
        }
        if (skipPermissionCheck !== undefined) {
            flagging.skip_permission_check = skipPermissionCheck;
        }
        return this.flagService.flag(flagging).then(data => {
          if (data[0] === true) {
            const flaggingAction = action === 'flag' ? true : false;
            entity[flag.name] = flaggingAction;
            if (storageKey) {
              this.storageService.storeEntity(storageKey, entity, 'nid');
            }
            console.log(flaggingAction);
            if (flaggingAction) {
              this.message.presentToast(flagToastMessage, 2000);
            }
            if (!flaggingAction) {
              this.message.presentToast(unflagToastMessage, 2000);
            }
          }
          return true;
          // return entity[flag.name];
        })
        .catch((err: any) => {
          console.error('Error Message: ', err);
          this.message.presentToast(err.status + err.statusText, 2000, 0, 'bottom', 'danger');
          entity[flag.name] = flagAction;
          return false;
          // return entity[flag.name];
        });
    } else {
      console.log(flagAction);
      entity[flag.name] = !flagAction;
      return false;
      // return flagAction;
    }
  }

  async confirmFlag(item: any, flag: FlagIonic, flagValue: boolean) {
    const wording = flagValue ? 'unflag' : 'flag';
    const alert = await this.alertCtrl.create({
      header: 'Confirm ' + flag.label,
      message: 'Are you sure you want to ' + wording + ' ' + item.contact_first_name + ' ' + item.contact_last_name + ' for ' + flag.label +'?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          role: 'submit',
          cssClass: 'warning',
        }
      ]
    });
    await alert.present();
    return await alert.onDidDismiss().then(async (res: any) => {
      if (res.role === 'submit') {
        return true;
      } else {
        return false;
      }
    });
  }

  unflagBadgePrinted(entity: any, key: string, newVal: any) {
    if (environment.badgeRequiresReprint.indexOf(key) !== -1) {
      const flag: FlagIonic = {
        fid: 4,
        entity_type: 'node',
        global: 1,
        name: 'badge_printed',
        label: 'Badge Printed',
        title: 'Badge Printed'
      };
      if (key === 'delegate_type') {
        let sameType = false;
        Object.keys(environment.delegateTypes).map(keys => {
          const value = environment.delegateTypes[keys];
          if (value.types.indexOf(entity[key]) !== -1 && value.types.indexOf(newVal) !== -1) {
            sameType = true;
          }
        });
        if (!sameType) {
          if (entity['flag_'+flag.name]) {
            this.message.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
            this.setupFlagMessageAndFlag(entity, flag, entity['flag_'+flag.name]);
          }
        }
      } else {
        if (entity['flag_'+flag.name]) {
          this.message.presentToast('This change requires the badge to be reprinted. Unflagging as printed.', 3000, 0, 'top', 'danger');
          this.setupFlagMessageAndFlag(entity, flag, entity['flag_'+flag.name]);
        }
      }
    }
  }

  async setupFlagMessageAndFlag(item: any, flag: FlagIonic, flagValue: boolean) {
    const flagMessage = 'Flagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const unflagMessage = 'Unflagged ' + flag.label + ' for ' + item.contact_first_name + ' ' + item.contact_last_name;
    const session = await this.storage.get('session');
    const newFlagStatus = await this.flagEntity(item, flag, flagValue, session, flagMessage, unflagMessage);
    item['flag_'+flag.name] = newFlagStatus;
  }

  async getActiveFlags() {
    const result: object = {};
    const allFlags: FlagIonic[] = [];
    for await (const flag of allFlags) {
      result[flag.category] = allFlags.filter(o => o.category === flag.category && o.status);
    }
    const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
    console.log(arr);
    return arr;
  }

  checkFlagDisabled(flag: any, entity: any): boolean {
    if (flag.disabled) {
      for (const depFlag of flag.disabled) {
        if (entity['flag_'+depFlag.name] === depFlag.value) {
          return true;
        }
      }
    } else if (flag.name !== 'not_attending' && entity.flag_not_attending) {
      return true;
    } else {
      return false;
    }
  }

  checkFlagHidden(flag: FlagIonic, entity: any, session: any): boolean {
    if (!flag.status || entity['flag_'+flag.name] === undefined || !session.user.flag_access.flag[flag.name]) {
      return true;
    }
    // const hasOpenOrder = entity.referenced_orders.map(o => o.state !== CommerceOrderStatus.invoiced);
    // if (flag.name === 'checked_in' && !hasOpenOrder.indexOf(true)) {
    //   return true;
    // }
    if (flag.name === 'checked_in' && entity.flag_delegate_paid) {
      return false;
    } else {
      return true;
    }
  }

  checkFlagHiddenAdmin(flag: FlagIonic, entity: any, session: any): boolean {
    const hasOpenOrder = entity.referenced_orders.map(o => o.state !== CommerceOrderStatus.invoiced);
    if (flag.name === 'checked_in' && !hasOpenOrder.indexOf(true) || !flag.status || entity['flag_'+flag.name] === undefined || !session.user.flag_access.flag[flag.name]) {
      return true;
    } else {
      return false;
    }
  }

  getPageTitleandDesc(objKey: string) {
    let pageDescription = '';
    switch (objKey) {
      case 'badgeFlags':
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner on the badge page.';
        break;
      case 'priceFlags':
        pageDescription = 'Check the flags to enable or disable flags from being shown on the payment page.';
        break;
      default:
        pageDescription = 'Check the flags to be automatically flagged when scanned with a code scanner or enable or disable flags from being shown on the check in page.';
        break;
    }
    return pageDescription;
  }
}



