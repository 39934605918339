import { Injectable } from '@angular/core';
import CommerceProducts from '../../assets/commerce/commerce_products.json';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor() { }

  async getAssetItems(file: string) {
    const items = [];
    await import(`../../assets/${file}.json`).then((results: any) => {
      for (const result of results.default) {
        if (result.status) {
            items.push(result);
        }
      }
    });
    return items;
  }

  getActiveProducts() {
    const activeProducts = [];
    for (const product of CommerceProducts) {
      if (product.status) {
        activeProducts.push(product);
      }
    }
    return activeProducts;
  }



}
