/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { FormInlineComponent } from 'src/app/form-inline/form-inline.component';
import { ViewService } from 'src/app/services/drupal7/drupal7-services.module';
import { DrupalFormControlObject, FlagIonic, Options, SystemConnection, ViewOptions } from 'src/app/services/drupal7/models';
import { FormFromJSON, FoundRegistrations, Pager } from 'src/app/services/drupal7/models/forms';
import { DelegateRegistration } from 'src/app/services/drupal7/models/node-types';
import { EntityServiceCustom } from 'src/app/services/entity.service';
import { FlagServiceCustom } from 'src/app/services/flag.service';
import { FormsService } from 'src/app/services/forms.service';
import { MessagesService } from 'src/app/services/messages.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment.prod';
import { Church } from '../manage-churches/manage-churches.component';

@Component({
  selector: 'app-manage-registrations',
  templateUrl: './manage-registrations.component.html',
  styleUrls: ['./manage-registrations.component.scss'],
})
export class ManageRegistrationsComponent implements OnInit {
  @ViewChild('searchInput', {static: false}) searchInput: IonSearchbar;
  @Input() church: Church;
  @Input() requestOptions: any;
  data: Array<any>;
  noResultsData = false;
  loading = true;
  headers: any;
  pager: Pager;
  resultsCount = 10;
  itemsPerPage = [5, 10, 20, 30, 40, 50, 60];
  inputData: string | number;

  inputNid: string;
  inputOrderId: string;
  inputAuthorId: string;
  inputGroupId: string;
  inputChurchNid: string;
  groupManagers: Array<Options>;

  formSchema: FormFromJSON;

  constructor(
    private message: MessagesService,
    private flag: FlagServiceCustom,
    public router: Router,
    private forms: FormsService,
    public storage: StorageService,
    private viewService: ViewService,
    private entity: EntityServiceCustom,
    private modalController: ModalController) {
      this.initialize();
     }

  ngOnInit() {
    this.get();
  }

  async initialize() {
    const session: SystemConnection = await this.storage.get('session');
    this.formSchema = await this.forms.getForm(session, environment.checkInType.entityType, environment.checkInType.bundle);
    this.getGroupManagers();
  }

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => 0;

  pagerChange(page: string = '') {
    this.message.showLoading('Loading page ' + page, false, 1500);
    this.get('', page, false);
  }

  search(input: any, showLoading: boolean = true) {
    if (input !== '' && input !== undefined) {
      if (showLoading) {
        this.message.showLoading('Searching for ' + input, false, 1500);
      }
      this.get(input, '', false);
    } else {
      this.get();
    }
  }

  resetSearch() {
    this.inputData = '';
    this.data = null;
    this.inputNid = null;
    this.inputOrderId = null;
    this.inputAuthorId = null;
    this.inputGroupId = null;
    this.inputChurchNid = null;
    this.message.showLoading('Resetting', false, 300);
  }

  async getGroupManagers() {
    const options: ViewOptions = {
      display_id: 'app_group_managers',
    };
    this.viewService.getView('available/group_manager', options).then((res: any) => {
      console.log(res);
      this.groupManagers = res;
    });
  }

  async get(id: string = '', page: string = '', showLoading: boolean = true) {
    if (showLoading) {
      this.message.showLoading('Loading registrations...' + page, false, 1500);
    }
    if (this.church) {
      this.inputChurchNid = this.church.nid;
    }
    const nid = this.inputNid ? this.inputNid : 'all';
    const orderId = this.inputOrderId ? this.inputOrderId : 'all';
    const authorId = this.inputAuthorId ? this.inputAuthorId : 'all';
    const groupId = this.inputGroupId ? this.inputGroupId : 'all';
    const churchId = this.inputChurchNid ? this.inputChurchNid : 'all';
    const options: ViewOptions = {
      display_id: 'app_manage_registrations',
      filters: {
        id,
        page,
        items_per_page: this.resultsCount
      },
      args: [nid, orderId, authorId, groupId, churchId]
    };

    this.entity.findRegistration('registration/manage', options).then((res: FoundRegistrations) => {
      if (res) {
        this.headers = res.headers;
        this.pager = res.pager;
        this.data = res.results;
        this.noResultsData = res.noResultsData;
        this.loading = false;
      }
    }, () => {
      this.noResultsData = true;
      this.loading = false;
    });
  }


  addRegistration() {
    const newDelegate: DelegateRegistration = {
      title: '',
      type: 'delegate_registration',
      contact_first_name: '',
      contact_last_name: '',
      contact_email: '',
      contact_phone_number: '',
      phone_country_code: '1',
      delegate_type: 'ministry_employee',
      gender: 'male',
      country: 'US',
      linked_church: '6615',
      author: 1
    };
    console.log(newDelegate);
    this.data.unshift(newDelegate);
  }

  async updateField(entity: any, key: string) {
    const session: SystemConnection = await this.storage.get('session');
    const form = await this.forms.getForm(session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      // for (const g of form.formFields) {
        const formField: DrupalFormControlObject = form.form_fields.filter(o => o.field_name === key)[0];
        // if (g.fields) {
        //   formField = g.fields.filter(o => o.field_name === key)[0];
        // }
        if (formField !== undefined) {
          const inputs = [];
          const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
          if (formField.options) {
            for (const option of formField.options) {
              inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
            }
          } else if (formField.ionic_type === 'boolean') {
            inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
          } else {
            inputs.push(
              {
                name: key,
                type: formField.type,
                id: key,
                placeholder: '',
                value: entity[key]
              }
            );
          }
          this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
            if (val) {
             return entity[key] = val;
            }
          });
        }
      // }
      return true;
    } else {
      return false;
    }
  }

  async flagEntity(item: any, flag: FlagIonic, flagValue: boolean) {
    this.flag.setupFlagMessageAndFlag(item, flag, flagValue);
  }

  async editModal(entity: any) {
    const modal = await this.modalController.create({
      component: FormInlineComponent,
      cssClass: 'edit',
      componentProps: {
        loadedEntity: entity,
        entityType: entity.entity_type,
        entityBundle: entity.bundle,
      }
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    if (res.data?.values) {
      console.log(res);
    }
  }


  cancelModal() {
    this.modalController.dismiss();
  }


}
