/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { PaymentService } from '../services/payment.service';
import USStates from '../../assets/address/us_states.json';
import { Storage } from '@ionic/storage-angular';
import { AlertController, ModalController } from '@ionic/angular';
import { MessagesService } from '../services/messages.service';
import { ElavonService } from '../services/elavon.service';
import { formatCurrency } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { FormsService } from '../services/forms.service';
import AdministrativeArea from '../../assets/address/administrative_area.json';
import { environment } from 'src/environments/environment.prod';
import { CardSwipeService } from '../services/cardswipe.service';
import { FlagServiceCustom } from '../services/flag.service';
import { FlagIonic, ViewOptions, ElavonTransaction, DrupalFormControlObject, Options, APICommerceOrder, CommerceCustomerProfile, CommerceLineItemEntity, CommerceOrderStatus, CommercePaymentTransactionStatus, CommerceProduct, CustomerProfileAttachToEntity, Payment, PaymentMethod, SystemConnection } from '../services/drupal7/models';
import { CommerceCheckoutService, CommerceCustomerProfileService, CommerceLineItemService, CommerceOrderService, CommercePaymentTransactionService, ViewService } from '../services/drupal7/drupal7-services.module';
import { AssetsService } from '../services/assets.service';
import { EntityServiceCustom } from '../services/entity.service';
import { Navigation, Router } from '@angular/router';
import { CommerceCustomPaymentTransactionResponse } from '../services/drupal7/models/commerce';
import { UserServiceCustom } from '../services/user.service';

@Component({
  selector: 'app-payment',
  templateUrl: 'payment.page.html',
  styleUrls: ['payment.page.scss']
})
export class PaymentPage implements OnInit {

  @Input() order: APICommerceOrder = null;
  @Input() appUser: object;
  @Input() session = null;
  @Input() modalMode: boolean;

  paymentMethods: Array<PaymentMethod>;
  billingControls: FormGroup;
  paymentControls: FormGroup;
  billingForm: Array<DrupalFormControlObject>;
  paymentJSON: Array<DrupalFormControlObject>;
  maxDate = (new Date().getFullYear() + 7).toString();
  minDate = (new Date().getFullYear()).toString();

  states: Array<Options>;
  administrativeArea = AdministrativeArea;
  dynamicAdministrativeArea: any;
  splitPayment = false;

  payment: Payment = {
    payment_method: 'elavon_direct',
    status: 'pending',
    uid: null,
    order_id: null,
    amount: 0,
    currency_code: 'USD',
    message: '',
    remote_id: 'slc_asia_app',
    remote_status: '',
  };
  products: Array<CommerceProduct>;
  showProducts: boolean;
  swipe_buffer = '';
  flags: Array<FlagIonic>;
  userFlags: Array<FlagIonic>;
  flagsByType: Array<FlagIonic>;
  orderStatus = CommerceOrderStatus;
  requiresBilling = true;
  requiresCard = true;

  constructor(
    private message: MessagesService,
    private elavon: ElavonService,
    private cardSwipe: CardSwipeService,
    private flag: FlagServiceCustom,
    private assetsService: AssetsService,
    private commerceOrderService: CommerceOrderService,
    private commerceLineItemService: CommerceLineItemService,
    private commerceCustomerProfileService: CommerceCustomerProfileService,
    private paymentService: PaymentService,
    private paymentTransactionService: CommercePaymentTransactionService,
    private commerceCheckoutService: CommerceCheckoutService,
    private forms: FormsService,
    private entity: EntityServiceCustom,
    private viewService: ViewService,
    private alertCtrl: AlertController,
    private user: UserServiceCustom,
    private modalController: ModalController,
    public storage: Storage,
    private router: Router
    ) {
      this.user.currentSession.subscribe(session => this.session = session);

    this.states = USStates;
    this.dynamicAdministrativeArea = USStates;
    this.paymentMethods = this.paymentService.getActivePaymentMethods('access');
    this.products = this.assetsService.getActiveProducts();

    if (!this.modalMode) {
      const nav: Navigation = this.router.getCurrentNavigation();
      const currentURL = this.router.url.split('/');
      const orderId = currentURL[2];
      this.initialize(orderId);
    }

    this.getBillingForm();
    this.getPaymentForm();
    console.log('listening for card swipe');
    document.addEventListener('keypress', (evt: KeyboardEvent) => {
      this.swipe(evt);
    });
  }

  async ngOnInit() {
    this.flags = await this.storage.get('flags_'+environment.checkInType.bundle);
    this.userFlags = await this.storage.get('flags_user');
    this.flagsByType = await this.storage.get('flags_by_type').then(res => res.priceFlags);
    if (this.order) {
      this.buildOrder(this.order);
    }
  }

  async initialize(orderId: string) {
    console.log(orderId);
    this.message.showLoading('Loading the order... ', false, 2000);
    this.payment = {
      payment_method: 'elavon_direct',
      status: 'pending',
      uid: null,
      order_id: null,
      amount: 0,
      currency_code: 'USD',
      message: '',
      remote_id: 'slc_asia_app',
      remote_status: '',
    };
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [orderId],
    };
    this.viewService.getView('registration/commerce-order', options).then((res: any) => {
      console.log(res);
      this.order = res[0];
      console.log(this.order);
      this.buildOrder(this.order);
    });
  }

  async navigateToPage() {
    this.router.navigate([''], {replaceUrl: true});
  }

  checkFlagDisabled(flag: any, entity: any) {
    return this.flag.checkFlagDisabled(flag, entity);
  }

  toggleSelectAll(e: any) {
    if (e) {
      this.order.commerce_line_items.map(item => item.splitPay = true);
    } else {
      this.order.commerce_line_items.map(item => item.splitPay = false);
    }
  }

  checkSplitAmount() {
    if (this.splitPayment) {
      let total = 0;
      for (const item of this.order.commerce_line_items) {
        if (item.splitPay) {
          total += item.commerce_total;
        }
      }
      this.payment.amount = total;
      this.payment.formattedAmount = formatCurrency(parseFloat(this.payment.amount.toFixed(2)) / 100, 'en-US', '$');
    } else {
      this.payment.amount = this.order.balance;
    }
  }

  async getBillingForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'commerce', 'billing');
    console.log(reactiveForm);
    this.billingControls = reactiveForm.formGroup;
    this.billingForm = reactiveForm.form_fields;
  }

  async getPaymentForm() {
    const reactiveForm = await this.forms.getForm(this.session, 'commerce', 'elavon_direct');
    this.paymentControls = reactiveForm.formGroup;
    this.paymentJSON = reactiveForm.form_fields;
  }

  async swipe(event) {
    // add global variable to check listener
    const res = await this.cardSwipe.cardSwipe(event);
    if (res) {
      const dateFormat = `${res.cardExpYear}-${res.cardExpMonth}-01T00:00:00-07:00`;
      this.paymentControls.controls.exp_month.setValue(dateFormat);
      this.paymentControls.controls.exp_year.setValue(dateFormat);
      // Show the expiration date
      this.showDatePicked(this.paymentJSON[0].fields[1]);
      this.showDatePicked(this.paymentJSON[0].fields[2]);

      this.paymentControls.controls.card_number.setValue(res.cardNum);
      this.billingControls.controls.commerce_customer_address['controls'].first_name.setValue(res.cardFirst);
      this.billingControls.controls.commerce_customer_address['controls'].last_name.setValue(res.cardLast);
    }
  }

  segmentChanged(paymentType: PaymentMethod) {
    this.payment.payment_method = paymentType.value;
    this.requiresBilling = paymentType.requires_billing;
    this.requiresCard = paymentType.requires_card;
    for (const paymentMethod of this.paymentMethods) {
      paymentMethod.fill = paymentMethod.value === paymentType.value ? 'solid' : 'outline';
    }
    console.log(this.payment);
  }

  updateAdministrativeAreas(country, options, addressField) {
    options = this.administrativeArea.filter(administrativeArea => administrativeArea.key === country.detail.value);
    if (options[0].value) {
      let newOptions = {};
      newOptions = Object.keys(options[0].value)
      .map(key => {
        const value = options[0].value[key];
        newOptions = {key, value};
        return newOptions;
      });
      this.dynamicAdministrativeArea = newOptions;
    } else {
      this.dynamicAdministrativeArea = null;
    }
  }

  showDatePicked(field) {
      this.paymentControls.get(field.field_name)['formattedValue'] = this.forms.formatDate(this.paymentControls, field);
  }

  // async switchRoles(order) {
  //   if (order.order_owner[0].hasRegularRole) {
  //     await this.message.showLoading('Switching order LBC church member pricing', true, 2100);
  //     const updatedUser = await this.user.updateUser(order.uid, {roles: [environment.user.churchMemberRole]}, this.session).toPromise();
  //     console.log(updatedUser);
  //     if (updatedUser) {
  //       return await this.reloadOrder();
  //     }
  //   }
  //   if (order.order_owner[0].hasChurchRole) {
  //     await this.message.showLoading('Switching order to regular delegate pricing ', true, 2100);
  //     const updatedUser = await this.user.updateUser(order.uid, {roles: [environment.user.regularUserRole]}, this.session).toPromise();
  //     console.log(updatedUser);
  //     if (updatedUser) {
  //       return await this.reloadOrder();
  //     }

  //   }
  //   if (!order.order_owner[0].hasRegularRole && !order.order_owner[0].hasChurchRole) {
  //     this.message.presentAlert('Access Denied', 'The role for this order owner cannot be modified.');
  //   }
  // }

  async updateField(entity: any, key: string) {
    const session: SystemConnection = await this.storage.get('session');
    const form = await this.forms.getForm(session, entity.entity_type, entity.bundle);
    if (form.formGroup.controls[key]) {
      // for (const g of form.formFields) {
        const formField: DrupalFormControlObject = form.form_fields.filter(o => o.field_name === key)[0];
        // if (g.fields) {
        //   formField = g.fields.filter(o => o.field_name === key)[0];
        // }
        if (formField !== undefined) {
          const inputs = [];
          const type = formField.ionic_type === 'list' ? 'radio' : formField.ionic_type;
          if (formField.options) {
            for (const option of formField.options) {
              inputs.push({label: option.value, value: option.key, checked: entity[key] === option.key ? true : false, type});
            }
          } else if (formField.ionic_type === 'boolean') {
            inputs.push({name: key, id: key, label: formField.label, value: entity[key], checked: entity[key], type: 'checkbox'});
          } else {
            inputs.push(
              {
                name: key,
                type: formField.type,
                id: key,
                placeholder: '',
                value: entity[key]
              }
            );
          }
          this.entity.presentUpdateFieldAlert(entity.entity_type, entity, formField, key, inputs).then(val => {
            if (val) {
             return entity[key] = val;
            }
          });
        }
      // }
      return true;
    } else {
      return false;
    }
  }

  async addProductToCart(product) {
    this.message.showLoading('Adding the ' + product.title + ' to order ' + this.order.order_id, true, 3000);
    const body: CommerceLineItemEntity = {
      order_id: this.order.order_id.toString(),
      commerce_product: product.id,
      type: 'product',
      quantity: '1'
    };
    this.commerceLineItemService.createLineItem(body).then(() => {
      this.reloadOrder();
    });
    // let order = {};
    // // const options = this.auth.setupRequestOptions('csrf', this.session);
    // this.commerce.getCommerceCart(this.requestOptions).subscribe(async res => {
    //   order = res;
    //   this.commerce.addProductToCart(product, this.requestOptions, this.order).subscribe(async lineItem => {
    //     await this.reloadOrder();
    //   });
    // });
  }

  async deleteLineItem(lineItem) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Remove',
      message: `Are you sure you want to remove ${lineItem.contact_first_name} ${lineItem.contact_last_name} (${lineItem.nid}) from Order ${this.order.order_id}? This cannot be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'DELETE',
          cssClass: 'primary',
          handler: async () => {
            this.commerceLineItemService.deleteLineItem(lineItem.line_item_id);
            await this.reloadOrder();
          }
        }
      ]

    });
    await alert.present();
  }

  overrideAmountConfirm() {
    console.log(this.payment);
    this.paymentService.overrideAmountConfirm(this.payment, this.order);
  }

  async reloadOrder() {
    this.message.showLoading('Refreshing the order... ', false, 2000);
    this.payment = {
      payment_method: 'elavon_direct',
      status: 'pending',
      uid: null,
      order_id: null,
      amount: 0,
      currency_code: 'USD',
      message: '',
      remote_id: 'slc_asia_app',
      remote_status: '',
    };
    const options: ViewOptions = {
      display_id: 'api_registration_orders',
      args: [this.order.order_id],
    };
    this.viewService.getView('registration/commerce-order', options).then((res: any) => {
      console.log(res);
      this.order = res[0];
      this.buildOrder(this.order);
    });
  }

  showPayment() {
    console.log(this.payment);
    console.log(this.session);
    console.log(this.order);
    console.log(this.billingControls.value);
    console.log(this.paymentControls.value);

  }

  buildOrder(res: APICommerceOrder) {
      // Setup the line item total
      if (res.commerce_line_items?.length) {
        let lineItemsTotal = 0;
        for (const lineItem of res.commerce_line_items) {
            lineItemsTotal += lineItem.commerce_total;
        }
        res.commerce_line_items_amount = lineItemsTotal;
        res.commerce_line_items_amount_formatted = formatCurrency(parseFloat(res.commerce_line_items_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      // Setup the billing info
      if (res.commerce_customer_profiles?.length) {
        res.commerce_customer_profiles = this.forms.convertObjectToArray(res.commerce_customer_profiles);
        console.log(this.billingForm);
        const customerAddressFields = this.billingForm[0].fields[3].fields;
        for (const field of customerAddressFields) {
          const val = res.commerce_customer_profiles[0][field.field_name];
          this.billingControls.controls.commerce_customer_address['controls'][field.field_name].setValue(val);
        }
      }
      // Setup the payment transactions
      if (res.commerce_payment_transactions?.length) {
        let transactionsTotal = 0;
        for (const transaction of res.commerce_payment_transactions) {
          if (transaction.status === CommercePaymentTransactionStatus.success) {
            const amount = Number(transaction.amount.toString().replace(/[^0-9.-]+/g,''));
            transactionsTotal += amount;
          }
        }
        res.commerce_order_payments_amount = +transactionsTotal.toFixed(2).replace('.', '');
        res.commerce_order_payments_amount_formatted = formatCurrency(parseFloat(res.commerce_order_payments_amount.toFixed(2)) / 100, 'en-US', '$');
      }

      if (res.order_owner?.length) {
        for (const owner of res.order_owner) {
          if (owner.rid) {
            const roles = owner.rid.split(',');
            Object.keys(roles).map(key => {
              owner.hasRegularRole = environment.user.roleTypes.RegularRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
            Object.keys(roles).map(key => {
              owner.hasChurchRole = environment.user.roleTypes.ChurchRoles.indexOf(roles[key]) !== -1 ? true : false;
            });
          }
        }
      }

      if (res.commerce_order_total !== 0) {
        this.payment.amount = res.balance;
      } else {
      this.payment.amount = 0;
    }
    this.payment.uid = this.session.user.uid;
    this.payment.order_id = res.order_id;
    // this.payment.receipt_email = res.mail;

    console.log(this.payment);
    this.order = res;
    console.log(this.order);
    return this.order;
  }

  submitBilling() {
    // Add additional properties to the body
    console.log(this.billingControls.value);
    const firstName = this.billingControls.value.commerce_customer_address.first_name;
    const lastName = this.billingControls.value.commerce_customer_address.last_name;
    this.billingControls.value.commerce_customer_address.name_line = firstName !== null && lastName !== null ? firstName + ' ' + lastName : null;
    if (this.order.commerce_customer_billing === '' || this.order.commerce_customer_billing === null) {
      // Create new customer profile if none exists on the order
      const profile: CustomerProfileAttachToEntity = {
        attach: {
          type: 'billing',
          status: '1',
          uid: this.session.user.uid,
          commerce_customer_address: this.billingControls.value.commerce_customer_address
        },
        order_id: +this.order.order_id,
        field_name: 'commerce_customer_billing'
      };
      this.commerceOrderService.attachProfileToEntity(profile).then(res => {
        console.log(res);
      });
    } else {
      this.billingControls.value.profile_id = +this.order.commerce_customer_billing;
      this.commerceCustomerProfileService.updateProfile(this.billingControls.value).then(res => {
        console.log(res);
      });
    }
  }

  sendPayment() {
    console.log(this.payment);
    console.log(this.session);
    console.log(this.order);
    console.log(this.billingControls.value);
    console.log(this.paymentControls.value);
    this.checkoutFlow();
  }

  async checkoutFlow() {
    if (this.requiresCard) {
      this.checkoutCreditCard();
    } else {
      this.checkoutOtherPaymentMethod();
    }
  }

  async checkoutCreditCard() {
    const payment = this.elavon.elavonPaymentTransactionProcessPrepare(this.billingControls.value.commerce_customer_address, this.paymentControls.value, this.payment, this.order, this.session);
    const view: ViewOptions = {
      view_name: 'api_registration_orders',
      display_id: 'page',
      args: [this.order.order_id],
      return_prop: 'order_id'
  };
    return this.commerceCheckoutService.createCheckoutFlow(this.order, this.billingControls.value, payment, view).then(res => {
      console.log(res);
      if (!res.elavon_response.status) {
        this.message.presentAlert(res.elavon_response.xml.errorName, res.elavon_response.xml.errorMessage);
      } else {
        const Amount = +res.elavon_response.xml.ssl_amount.replace('.', '');
        const formattedAmount = formatCurrency(Amount / 100, 'en-US', '$');
        this.completeCheckoutFlow(res, Amount, formattedAmount);
      }
      this.reloadOrder();
      console.log(this.order);
    });
  }

  async checkoutOtherPaymentMethod() {
    if (this.payment.payment_method) {
      this.payment.status = CommercePaymentTransactionStatus.success;
      // Make sure the amount is properly formatted
      this.payment.amount = parseInt(this.payment.amount.toString().replace('.', ''), 10);
      this.message.showLoading('Submitting your payment...', true, 3000);
        this.paymentTransactionService.createPaymentTransaction(this.payment)
        .then(async (res) => {
        console.log(res);
        const formattedAmount = formatCurrency(res.commerce_payment_transaction.amount / 100, 'en-US', '$');
        this.completeCheckoutFlow(res, res.commerce_payment_transaction.amount, formattedAmount);
      });
    }
  }

  sendPaymentV1() {
    console.log(this.payment);
    console.log(this.session);
    console.log(this.order);
    console.log(this.billingControls.value);
    console.log(this.paymentControls.value);

    if (this.requiresCard) {

      if (this.billingControls.valid) {
        this.submitBilling();
      }
      this.message.showLoading('Submitting your payment...', true, 1000);
      this.elavon.elavonPaymentTransactionProcess(this.billingControls.value.commerce_customer_address, this.paymentControls.value, this.payment, this.order, this.session).then(async (res: ElavonTransaction) => {
        console.log(res);
        const errorCode = res.elavon_response.xml.errorCode;
        const status = res.elavon_response.status;
        if (!status) {
           return this.message.presentAlert('Error', res.elavon_response.msg);
        }
        if (errorCode) {
          const errorName = res.elavon_response.xml.errorName;
          const errorMessage = res.elavon_response.xml.errorMessage;
          this.message.presentAlert(errorName, errorMessage);
        }
        const Amount = +res.elavon_response.xml.ssl_amount.replace('.', '');
        const formattedAmount = formatCurrency(Amount / 100, 'en-US', '$');
        this.completeCheckoutFlow(res, Amount, formattedAmount);
      });
    }
    if (this.payment.payment_method) {
      this.payment.status = CommercePaymentTransactionStatus.success;
      // Make sure the amount is properly formatted
      this.payment.amount = parseInt(this.payment.amount.toString().replace('.', ''), 10);
      this.message.showLoading('Submitting your payment...', true, 3000);
        this.paymentTransactionService.createPaymentTransaction(this.payment)
        .then(async (res: any) => {
        console.log(res);
        if (res.commerce_payment_transaction) {

        }
        const formattedAmount = formatCurrency(res.commerce_payment_transaction.amount / 100, 'en-US', '$');
        this.completeCheckoutFlow(res, res.commerce_payment_transaction.amount, formattedAmount);
      });
    }
  }

  async completeCheckoutFlow(res: any, amount: any, formattedAmount: string) {
    if (amount === this.order.commerce_order_better_balance) {
      const order = await this.reloadOrder();
      this.modalController.dismiss({
        order
      }, 'submit');
    } else {
      await this.reloadOrder();
      this.message.presentAlert('Payment Received', 'A payment in the amount of ' + formattedAmount +
      ' has been added to order ' + this.order.order_id + '. It looks like this order still has a balance of ' +
      res.commerce_order.commerce_order_balance_formatted + '. Please complete payment for this order.');
    }
  }

  cancelModal() {
    this.modalController.dismiss({submit: false}, 'cancel');
  }

}
