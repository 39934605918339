/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserServiceCustom } from '../services/user.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import { FlagIonic } from '../services/drupal7/models/flag';


@Component({
  selector: 'app-flag-settings',
  templateUrl: './flag-settings.component.html',
  styleUrls: ['./flag-settings.component.scss'],
})
export class FlagSettingsComponent implements OnInit {
  @Input() session: any;
  @Input() flags: Array<FlagIonic>;
  @Input() storageKey: string;
  @Input() objKey: string;
  @Input() pageTitle: string;
  @Input() pageDescription: string;
  constructor(
    public storage: Storage,
    private modalController: ModalController,
    private user: UserServiceCustom
  ) {}

  async ngOnInit() {}

  bulkToggle(property: string) {
    Object.values(this.flags).map(k => k[property] = !k[property]);
  }

  async refreshFlagAccess() {
    const result = await this.user.setFlagAccess(this.session, environment.checkInType.entityType, environment.checkInType.bundle, 'session');
    this.flags = result.flags;
  }

  async save() {
    await this.storage.get(this.storageKey).then(async (storedObj) => {
      console.log(storedObj);
      if (storedObj[this.objKey]) {
          storedObj[this.objKey] = this.flags;
      } else {
          storedObj = this.flags;
        }
      return await this.storage.set(this.storageKey, storedObj);
    });
    this.modalController.dismiss(true);
  }

}

export interface FlagSetting {
  storageKey: string;
  objKey: string;
  label: string;
  icon: string;
  status: boolean;
}
