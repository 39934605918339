/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap, map, catchError, finalize, retry, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { SystemConnection } from './drupal7/models';
import { CommerceOrder, CommerceCustomerProfile } from './drupal7/models/commerce';

export const STORAGE_REQ_KEY = 'storedreq_';
export const STORAGE_REQ_USERS = 'storedreq_user';
export const STORAGE_REQ_NODES = 'storedreq_node_application';
export const STORAGE_REQ_PAYMENTS = 'storedreq_commerce_payment_transaction';
export const STORAGE_REQ_ORDERS = 'storedreq_commerce_order';
export const STORAGE_REQ_LINE_ITEMS = 'storedreq_commerce_line_item';
export const STORAGE_REQ_CUSTOMER_PROFILE = 'storedreq_commerce_customer_profile';

export interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  reqID: string;
  id: string;
  entityType: string;
  send: boolean;
}

interface SyncResults {
  user?: object;
  entities?: object;
  orders?: object;
  line_items?: object;
  customer_profiles?: object;
  commerce_payment_transactions?: object;
}
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage,
    private http: HttpClient,
    public alertCtrl: AlertController,
    private toastController: ToastController) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  getEach() {
    this._storage.forEach((value, key, index) => {
      if (key.startsWith('storedreq')) {
        this.get(key).then(val => {
          console.log(val);
        });
      }
    });
  }

  async get(key: string) {
    return await this.storage?.get(key);
  }

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map(storedOperations => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          console.log(storedObj);
          return storedObj;
        } else {
          console.log('no local data for type: ' + key);
          return of(false).toPromise();
        }
      })
    );
  }

  async storeRecord(nid: number, order: number, feedbackData: FeedbackData) {
    const storageKey = 'stored_records';
    return this._storage.get(storageKey).then(storedObj => {
        if (storedObj) {
          const index = storedObj.map((o) => o.nid).indexOf(nid);
          if (index !== -1) {
            storedObj[index] = {nid, order, feedbackData};
          } else {
            storedObj.unshift({nid, order, feedbackData});
          }
        } else {
          storedObj = [{nid, order, feedbackData}];
        }
        return this._storage.set(storageKey, storedObj);
    });
  }

  async deleteRecord(nid: string) {
    const storageKey = 'stored_records';
    const entities = await this._storage.get(storageKey);
    const storedObj = entities.filter(record => record.nid !== nid);
    return this._storage.set(storageKey, storedObj);
  }

  async storeRequest(url, type, data, storageKey, uid) {
    const toast = this.toastController.create({
      message: `Your data is stored locally because you seem to be offline.`,
      duration: 1500,
      position: 'bottom'
    });
    toast.then(res => res.present());

    const action: StoredRequest = {
        url,
        type,
        data,
        time: new Date().getTime(),
        reqID: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
        id: uid,
        entityType: storageKey,
        send: true
      };

    return this._storage.get(STORAGE_REQ_KEY + storageKey).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);
        if (storedObj) {
          storedObj.push(action);
        } else {
          storedObj = [action];
        }
        // Save old & new local transactions back to Storage
        return this._storage.set(STORAGE_REQ_KEY + storageKey, JSON.stringify(storedObj));
    });
  }

  async updateRequest(op: StoredRequest, storageKey) {
    let storedObj = await this._storage.get(storageKey);
    storedObj = JSON.parse(storedObj);
    const index = storedObj.map((o) => o.reqID).indexOf(op.reqID);
    storedObj[index] = op;
    return this._storage.set(storageKey, JSON.stringify(storedObj));
  }

  async deleteRequest(op: StoredRequest, storageKey) {
    let entities = await this._storage.get(storageKey);
    entities = JSON.parse(entities);
    const storedObj = entities.filter(obj => obj.reqID !== op.reqID);
    return this._storage.set(storageKey, JSON.stringify(storedObj));
  }

  async storeNotification(storageKey: string, notification: PushNotificationSchema) {
    let newEntity = [];
    await this._storage.get(storageKey).then((storedObj: PushNotificationSchema[]) => {
      if (storedObj) {
        const index = storedObj.map((o: PushNotificationSchema) => o.id).indexOf(notification.id);
        if (index !== -1) {
          storedObj[index] = notification;
        } else {
          storedObj.unshift(notification);
        }
      } else {
          storedObj = [notification];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async deleteNotification(storageKey: string, notification: PushNotificationSchema) {
    const notifications: PushNotificationSchema[] = await this._storage.get(storageKey);
    console.log('the stored notifications.', notifications);
    const storedObj = notifications.filter(obj => obj.id !== notification.id);
    return await this._storage.set(storageKey, storedObj);
  }

  async subscribeTopic(storageKey: string, topic: string) {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o.id).indexOf(topic);
        if (index !== -1) {
          storedObj[index] = topic;
        } else {
          storedObj.push(topic);
        }
      } else {
          storedObj = [topic];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async unsubscribeTopic(storageKey: string, topic: string) {
    const entities: Array<string> = await this._storage.get(storageKey);
    const storedObj = entities.filter(k => k !== topic);
    return this._storage.set(storageKey, storedObj);
  }

  async storeEntity(storageKey: string, entity: any, entityKey: 'nid' | 'id' | 'item_id') {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o[entityKey]).indexOf(entity[entityKey]);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  async clear() {
    this._storage.forEach((v, k, i) => {
      if (!k.startsWith('login_')) {
        this._storage.remove(k);
      }
    });
  }

  async clearBiometrics() {
    this._storage.forEach((v, k, i) => {
      if (k.startsWith('login_BiometryType')) {
        this._storage.remove(k);
      }
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey).then(res => res).catch(() => false);
  }

  // async prepare(storageKey, op: StoredRequest, options, user?, order?: CommerceOrder,
  //   customerProfile?: CommerceCustomerProfile, authOptions?): Promise<any> {
  //   // Set the headers for the request
  //   op.data.options = options;

  //   if (op.send) {
  //     switch (op.entityType) {
  //       case 'user':
  //           return await this.sendPostRequest(op, storageKey, authOptions).toPromise();
  //       case 'node_application':
  //         op.data.body.author = user.entity.uid;
  //         return await this.sendPostRequest(op, storageKey).toPromise();
  //       case 'commerce_order':
  //         if (op.type === 'post') {
  //           op.data.body.uid = +user.entity.uid;
  //           return await this.sendPostRequest(op, storageKey).toPromise();
  //         }
  //         if (op.type === 'put') {
  //           op.url = environment.commerce.commerce_order.fetch + order.order_id + '.json';
  //           op.data.body.commerce_customer_billing = customerProfile.profile_id;
  //           op.data.body.mail = order.mail;
  //           return await this.sendPutRequest(op, storageKey).toPromise();
  //         }
  //         break;
  //       case 'commerce_customer_profile':
  //         if (op.type === 'post') {
  //           op.data.body.uid = user.entity.uid;
  //           return await this.sendPostRequest(op, storageKey).toPromise();
  //         }
  //         if (op.type === 'put') {
  //           op.url = environment.commerce.commerce_customer_profile.fetch + order.commerce_customer_billing + '.json';
  //           return await this.sendPutRequest(op, storageKey).toPromise();
  //         }
  //         break;
  //       case 'commerce_payment_transaction':
  //         op.data.body.uid = user.entity.uid;
  //         op.data.body.order_id = order.order_id;
  //         return await this.sendPostRequest(op, storageKey).toPromise();
  //       case 'commerce_line_item':
  //         op.data.body.order_id = order.order_id;
  //         return await this.sendPostRequest(op, storageKey).toPromise();
  //       default:
  //         break;
  //     }
  //   } else {
  //     switch (op.entityType) {
  //       case 'user':
  //         const storedUser = {id: op.id, entity: {uid: environment.appSettings.AppUserUID}};
  //         this.deleteRequest(op, storageKey);
  //         return storedUser;
  //       case 'node':
  //         const storedApplication = {id: op.id, entity: {uid: environment.appSettings.AppUserUID}};
  //         this.deleteRequest(op, storageKey);
  //         return storedApplication;
  //       default:
  //         break;
  //     }
  //   }
  // }

  // sendPostRequest(op: StoredRequest, storageKey, authOptions?) {
  //   console.log(op);
  //   console.log(storageKey);
  //   return this.http.post(op.url, op.data.body, op.data.options).pipe(
  //     map(async (data: any) => {
  //       const newEntity = {id: op.id, entity: data};
  //       this.deleteRequest(op, storageKey);
  //       return newEntity;
  //     }),
  //     catchError(async (e) => {
  //       op.send = false;
  //       this.updateRequest(op, storageKey);
  //       console.error('Error Message: ', e);
  //       return throwError(e);
  //     }));
  // }

  // sendPutRequest(op: StoredRequest, storageKey, authOptions?) {
  //   return this.http.put(op.url, op.data.body, op.data.options).pipe(
  //     map(async (data: any) => {
  //       const newEntity = {id: op.id, entity: data};
  //       this.deleteRequest(op, storageKey);
  //       return newEntity;
  //     }),
  //     catchError(async e => {
  //       op.send = false;
  //       this.updateRequest(op, storageKey);
  //       console.error('Error Message: ', e);
  //       return this.checkLocalStorageForSync();
  //     }));
  // }

  // async loginServiceWorker(): Promise<Observable<SystemConnection>> {
  //   const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  //   return await this.http.post<any>(environment.user.login, JSON.stringify(environment.appSettings.AppUser),
  //   requestOptions).toPromise().then(async loggedInUser => {
  //     const currentRoles = Object.keys(loggedInUser.user.roles).map(key => loggedInUser.user.roles[key]);
  //     const newRoles = [];
  //     for (const role of currentRoles) {newRoles.push(role);}
  //     loggedInUser.user.user_roles = newRoles;
  //     await this._storage.set('serviceWorker', loggedInUser);
  //     return loggedInUser;
  //   });
  // }

  // async getServiceWorker() {
  //   let serviceWorker = await this._storage.get('serviceWorker');
  //   if (!serviceWorker) {
  //     serviceWorker = await this.loginServiceWorker();
  //   }
  //   return await serviceWorker;
  // }

  // async checkLocalStorageForSync(): Promise<object> {
  //   const serviceWorker = await this.getServiceWorker();
  //   const storedUsers = await this._storage.get(STORAGE_REQ_USERS);
  //   const entities = JSON.parse(storedUsers);
  //   const response = [];
  //   const headersCSRF = {
  //     'Content-Type': 'application/json',
  //     'X-Cookie': serviceWorker.session_name + '=' + serviceWorker.sessid,
  //     'X-CSRF-Token': serviceWorker.token,
  //   };
  //   const options = {headers: new HttpHeaders(headersCSRF)};
  //   if (entities && entities.length > 0) {
  //     for (const req of entities) {
  //       const results: SyncResults = {};
  //       const requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  //       const newUser = await this.prepare(STORAGE_REQ_USERS, req, requestOptions, null, null, null, options);
  //       results.user = newUser;
  //       results.entities = await this.checkLocalStorageNewEntities(newUser, options, STORAGE_REQ_NODES);
  //       const newOrder = await this.checkLocalStorageNewEntities(newUser, options, STORAGE_REQ_ORDERS);
  //       if (newOrder && newOrder.length) {
  //         const orderEntity = newOrder[0].entity;
  //         results.line_items = await this.checkLocalStorageNewEntities(newUser, options, STORAGE_REQ_LINE_ITEMS, orderEntity);
  //         const newCommerceCustomerProfile = await this.checkLocalStorageNewEntities(newUser, options,
  //           STORAGE_REQ_CUSTOMER_PROFILE, orderEntity);
  //         const profileEntity = newCommerceCustomerProfile[0].entity;
  //         results.customer_profiles = newCommerceCustomerProfile;
  //         const updateOrder = await this.checkLocalStorageUpdateEntities(newUser, options, STORAGE_REQ_ORDERS, orderEntity, profileEntity);
  //         results.commerce_payment_transactions = await this.checkLocalStorageNewEntities(newUser, options,
  //           STORAGE_REQ_PAYMENTS, orderEntity);
  //         results.orders = await this.http.get(environment.commerce.commerce_order.order + orderEntity.order_id + '.json',
  //         options).toPromise();
  //       }
  //        response.push(results);
  //     }
  //     console.log(response);
  //     const toast = this.toastController.create({
  //       message: `Local data succesfully synced to API!`,
  //       duration: 3000,
  //       position: 'bottom'
  //     });
  //     toast.then(res => res.present());

  //     return response;
  //   } else {
  //     console.log('No data to sync of type: ' + STORAGE_REQ_USERS);
  //   }
  // }

  // async checkLocalStorageForOrphanedData(): Promise<object> {
  //   const serviceWorker = await this.getServiceWorker();
  //   const response = [];
  //   const headersCSRF = {
  //     'Content-Type': 'application/json',
  //     'X-Cookie': serviceWorker.session_name + '=' + serviceWorker.sessid,
  //     'X-CSRF-Token': serviceWorker.token,
  //   };
  //   const options = {headers: new HttpHeaders(headersCSRF)};
  //   const results: SyncResults = {};
  //   const appUser = {id: environment.appSettings.AppUserUID, entity: {user: {entity: {uid: environment.appSettings.AppUserUID}}}};
  //   results.entities = await this.checkLocalStorageNewEntities(appUser, options, STORAGE_REQ_NODES);
  //   const newOrder = await this.checkLocalStorageNewEntities(appUser, options, STORAGE_REQ_ORDERS);
  //   if (newOrder && newOrder.length) {
  //     const orderEntity = newOrder[0].entity;
  //     results.line_items = await this.checkLocalStorageNewEntities(appUser, options, STORAGE_REQ_LINE_ITEMS, orderEntity);
  //     const newCommerceCustomerProfile = await this.checkLocalStorageNewEntities(appUser, options,
  //       STORAGE_REQ_CUSTOMER_PROFILE, orderEntity);
  //     const profileEntity = newCommerceCustomerProfile[0].entity;
  //     results.customer_profiles = newCommerceCustomerProfile;
  //     await this.checkLocalStorageUpdateEntities(appUser, options, STORAGE_REQ_ORDERS, orderEntity, profileEntity);
  //     results.commerce_payment_transactions = await this.checkLocalStorageNewEntities(appUser, options, STORAGE_REQ_PAYMENTS, orderEntity);
  //     results.orders = await this.http.get(environment.commerce.commerce_order.order + orderEntity.order_id + '.json', options).toPromise();
  //   }
  //   response.push(results);

  //   console.log(response);
  //   const toast = this.toastController.create({
  //     message: `Orphaned local data succesfully synced to API!`,
  //     duration: 3000,
  //     position: 'bottom'
  //   });
  //   toast.then(res => res.present());

  //   return response;
  // }

  // async checkLocalStorageNewEntities(user, options, storageKey, order?: CommerceOrder): Promise<any> {
  //     let entities = await this._storage.get(storageKey);
  //     entities = JSON.parse(entities);
  //     if (entities && entities.length > 0) {
  //       const response = [];
  //       const result = entities.filter(obj => obj.id === user.id);
  //       if (result !== -1) {
  //         for (const req of result) {
  //           if (req.type === 'post') {
  //             if (storageKey === STORAGE_REQ_NODES || storageKey === STORAGE_REQ_ORDERS) {
  //               const newEntity = await this.prepare(storageKey, req, options, user);
  //               response.push(newEntity);
  //             }
  //             if (storageKey === STORAGE_REQ_LINE_ITEMS || storageKey === STORAGE_REQ_PAYMENTS) {
  //               const newCommerceEntity = await this.prepare(storageKey, req, options, user, order);
  //               response.push(newCommerceEntity);
  //             }
  //             if (storageKey === STORAGE_REQ_CUSTOMER_PROFILE) {
  //               const newCommerceEntity = await this.prepare(storageKey, req, options, user);
  //               response.push(newCommerceEntity);
  //             }
  //           }
  //         }
  //       }
  //       return response;
  //     } else {
  //       console.log('No data to sync of type: ' + storageKey);
  //       return of(false).toPromise();
  //     }
  // }

  // async checkLocalStorageUpdateEntities(user, options, storageKey, order?: CommerceOrder,
  //   customerProfile?: CommerceCustomerProfile): Promise<any> {
  //     let entities = await this._storage.get(storageKey);
  //     entities = JSON.parse(entities);
  //     if (entities && entities.length > 0) {
  //       const response = [];
  //       const result = entities.filter(req => req.id === user.id);
  //       if (result !== -1) {
  //         for (const req of result) {
  //           if (req.type === 'put') {
  //             if (storageKey === STORAGE_REQ_ORDERS) {
  //               const updatedEntity = await this.prepare(storageKey, req, options, user, order, customerProfile);
  //               response.push(updatedEntity);
  //             }
  //             if (storageKey === STORAGE_REQ_CUSTOMER_PROFILE) {
  //               const newCommerceEntity = await this.prepare(storageKey, req, options, user, order, customerProfile);
  //               response.push(newCommerceEntity);
  //             }
  //           }
  //         }
  //       }
  //       return response;
  //     } else {
  //       console.log('No data to update of type: ' + storageKey);
  //       return of(false);
  //     }
  // }



}


export interface FeedbackData {
  first_name: string;
  last_name: string;
  email?: string;
  phone_number?: string;
  type?: string;
  nid: number;
  easy?: string;
  heard?: string;
  helpful?: string;
  futureSessions?: string;
  changes?: string;
  comments?: string;
}
